import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { uploadImageAPI } from 'src/api/uploader';
import { AMAZON_IMAGE_URL } from 'src/utils/consts';
import { UploaderIcon } from './icons';

const StyledUploader = styled.div`
  .block-upload {
    display: flex;
    height: 112px;
    border: 2px dashed #009ef7;
    box-sizing: border-box;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &__container-text {
      &_part-1 {
        font-size: 14px;
        font-weight: 700;
        color: #009ef7;
      }

      &_part-2 {
        font-size: 12px;
        font-weight: 400;
        color: #9899ac;
      }
    }
  }
`;

const UploaderPreview = styled.div`
  display: flex;
  flex-direction: column;

  .imagePreview {
    width: 300px;

    img {
      max-width: 350px;
      max-height: 112px;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  .imageDetails {
    display: flex;
    justify-content: space-between;
  }

  .imageInfo {
    display: flex;
    gap: 10px;
  }
`;

export const FileUploader = ({ imagePath, setImageKey }: any) => {
  const [file, setFile] = React.useState<any>([]);
  const imageObj = file[0];

  useEffect(() => {
    if (file.length > 0) {
      getImageKey();
    }
  }, [file]);

  const getImageKey = async () => {
    const formData = new FormData();
    formData.append('file', imageObj);

    await uploadImageAPI(formData)
      .then(item => {
        setImageKey(item.data.Key);
        setFile({ ...file, imageKey: item.data.Key });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFile => {
      setFile(
        acceptedFile.map(file =>
          Object.assign(acceptedFile[0], {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  // const removeImage = () => {
  //   uploadImageAPI(imageObj.id)
  //     .then(() => {
  //       setFile([]);
  //       // setErrMessage({ isActive: null, message: '' });
  //     })
  //     .catch((err: any) => {
  //       console.log(err);
  //     });
  // };

  const isPreview = Boolean(imagePath);
  const isBlob = Boolean(imageObj?.preview);
  // console.log('isBlob', isBlob);
  // console.log('isPreview', isPreview);

  return (
    <>
      {isBlob && !isPreview ? (
        <UploaderPreview {...getRootProps()}>
          <>
            <input {...getInputProps()} />
            <div className="imagePreview">
              <img width="300" src={imageObj?.preview} alt="" />
            </div>
            {imageObj?.preview && (
              <div className="imageDetails">
                <div className="imageInfo">
                  <span>{imageObj?.path}</span>
                </div>
              </div>
            )}
          </>
        </UploaderPreview>
      ) : (
        <>
          {isPreview && !isBlob ? (
            <UploaderPreview {...getRootProps()}>
              <>
                <input {...getInputProps()} />
                <div className="imagePreview">
                  <img width="300" src={`${AMAZON_IMAGE_URL}${imagePath}`} alt="" />
                </div>
                {isPreview && (
                  <div className="imageDetails">
                    <div className="imageInfo">
                      <span>{imageObj?.path}</span>
                    </div>
                  </div>
                )}
              </>
            </UploaderPreview>
          ) : (
            <>
              {isPreview && isBlob ? (
                <UploaderPreview {...getRootProps()}>
                  <>
                    <input {...getInputProps()} />
                    <div className="imagePreview">
                      <img width="300" src={imageObj?.preview} alt="" />
                    </div>
                    {imageObj?.preview && (
                      <div className="imageDetails">
                        <div className="imageInfo">
                          <span>{imageObj?.path}</span>
                        </div>
                      </div>
                    )}
                  </>
                </UploaderPreview>
              ) : (
                <StyledUploader>
                  <div className="EventMap-main__content-file-uploader">
                    <div {...getRootProps()} className="block-upload">
                      <input {...getInputProps()} />
                      <UploaderIcon />
                      <div className="block-upload__container-text">
                        <div className="block-upload__container-text_part-1">Click to Upload File</div>
                        <div className="block-upload__container-text_part-2">or Drag and drop files here</div>
                      </div>
                    </div>
                  </div>
                </StyledUploader>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
