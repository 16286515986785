import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { StyledModal } from './styled';
import { addNewGood } from '../../api/shop';
import { GoodData } from './types';
import { Button, FileUploader, Input } from '../common';
import { ModalIconClose } from '../common/icons';

const validateSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  externalLink: Yup.string().required('External link is required'),
  image: Yup.string().required('Image is required'),
});

interface ModalProps {
  blockId: string;
}

export const ModalAddNewGood = (props: ModalProps): JSX.Element => {
  const { blockId } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const [imageKey, setImageKey] = React.useState<string>('');

  const initialValues: GoodData = {
    title: '',
    externalLink: '',
    image: '',
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Button onClick={toggleModal} name="Legg til ny vare" title="Add New Item" background="#00ADEF" color="white" />
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <div className="modalHeader">
          <div>Add New Item</div>
          <div className="iconButton" onClick={toggleModal}>
            <ModalIconClose />
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={async values => {
            const formValues = {
              ...values,
              image: `${imageKey}`,
            };

            await addNewGood(blockId, formValues);
            await toggleModal();
            await location.reload();
          }}
        >
          {(formik: any) => {
            const { errors, touched, isSubmitting, handleSubmit, setFieldValue } = formik;

            React.useEffect(() => {
              setFieldValue('image', imageKey);
            }, [imageKey]);

            return (
              <Form className="col" onSubmit={handleSubmit}>
                <div className="modalBody">
                  <div className="col">
                    <Input name="title" label="Title" placeholder="name" />
                    <Input name="externalLink" label="Link" placeholder="link" />
                    <label className="label">Image</label>
                    <FileUploader name="image" imagePath={imageKey} setImageKey={setImageKey} />
                    {touched.image && errors.image ? <div className="error">{errors.image}</div> : null}
                  </div>
                </div>

                <div className="modalFooter">
                  <Button onClick={toggleModal} name="Cancel" color="#00ADEF" />
                  <Button type="submit" name="Save" background="#00ADEF" disabled={isSubmitting} color="white" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </StyledModal>
    </div>
  );
};
