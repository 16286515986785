import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { getArticleById, editArticleById } from '../../api/articles';
import { FileUploader, Button, Input } from '../common';
import FormikControl from 'src/utils/FormikControl';
import { StyledModal } from './styled';
import { ArticleData } from './types';
import { ModalIconClose } from '../common/icons';
import ModalUnsavedDataVariant from '../common/ModalUnsavedDataVarianWithoutRedux';

const validateSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  subTitle: Yup.string().required('Subtitle is required'),
  description: Yup.string().required('Text is required'),
  cover: Yup.string().required('Article cover is required'),
});

export const EditArticleModal = (props: any): JSX.Element => {
  const { articleId } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenSaveModal, setIsOpenSaveModal] = React.useState(false);
  const [article, setArticle] = React.useState<ArticleData>({ title: '', subTitle: '', description: '', cover: '' });
  const [imageKey, setImageKey] = React.useState<string>('');
  const [isDirty, setIsDirty] = React.useState(false);

  if (!articleId) {
    return <></>;
  }

  const openModal = () => setIsOpen(true);
  const toggleModal = () => {
    if (isDirty) {
      setIsOpenSaveModal(true);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const getArticle = async () => {
    const article = await getArticleById(articleId);
    setArticle(article);
  };

  React.useEffect(() => {
    if (isOpen) {
      getArticle();
    }
  }, [isOpen]);

  const initialValues: ArticleData = {
    title: article.title,
    subTitle: article.subTitle,
    description: article.description,
    cover: article.cover,
  };

  return (
    <>
      <Button onClick={openModal} name="Edit" color="#00ADEF" />
      <ModalUnsavedDataVariant isOpen={isOpenSaveModal} setIsOpen={setIsOpenSaveModal} closeMainModal={setIsOpen} />
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <div className="modalHeader">
          <div>Edit Article</div>
          <div className="iconButton" onClick={toggleModal}>
            <ModalIconClose />
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={async values => {
            const formValues = {
              ...values,
              cover: `${imageKey || article.cover}`,
            };

            await editArticleById(articleId, formValues);
            await toggleModal();
            await location.reload();
          }}
        >
          {(formik: any) => {
            const { isSubmitting, setFieldValue, touched, errors, handleSubmit, dirty } = formik;

            React.useEffect(() => {
              setIsDirty(dirty);
            }, [dirty]);

            React.useEffect(() => {
              setFieldValue('description', article.description);
            }, [article]);

            return (
              <Form className="col" onSubmit={handleSubmit}>
                <div className="modalBody">
                  <div className="twoColumns">
                    <div className="col">
                      <Input name="title" label="Title" placeholder="f.e. Lady Gaga" />
                      <Input name="subTitle" label="Subtitle" placeholder="f.e. Lady Gaga" />
                    </div>

                    <div className="col">
                      <label className="label">Article cover</label>
                      <FileUploader imagePath={article?.cover} setImageKey={setImageKey} />
                      {touched?.cover && errors?.cover ? <div className="error">{errors?.cover}</div> : null}
                    </div>
                  </div>

                  <FormikControl control="editor" name="description" label="Article text" />
                  {touched?.description && errors?.description ? (
                    <div className="error">{errors?.description}</div>
                  ) : null}
                </div>

                <div className="modalFooter">
                  <Button onClick={toggleModal} name="Cancel" color="#00ADEF" type="button" />
                  <Button type="submit" name="Save" background="#00ADEF" color="white" disabled={isSubmitting} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </StyledModal>
    </>
  );
};
