import { UserActions, UserActionType } from '../types/users';
import { login } from '../../api/users';
import { USER_AUTH_COMMON_TOKEN, USER_AUTH_REFRESH_TOKEN } from '../../utils/consts';

export const loginUser = (email: string, password: string) => async (dispatch: any) => {
  try {
    const { data } = await login(email, password);
    if (data.access_token) {
      localStorage.setItem(USER_AUTH_COMMON_TOKEN, JSON.stringify(data.access_token));
      localStorage.setItem(USER_AUTH_REFRESH_TOKEN, JSON.stringify(data.refresh_token));
      dispatch({ type: UserActionType.LOGIN_USER_SUCCESS, payload: data.access_token });
    } else {
      dispatch({ type: UserActionType.LOGIN_USER_ERROR, payload: 'Invalid login or password' });
    }
  } catch (e) {
    console.log('err');
    dispatch({ type: UserActionType.LOGIN_USER_ERROR, payload: 'Invalid login or password' });
  }
};

export const logoutUser = (): UserActions => {
  return { type: UserActionType.LOGOUT_USER_SUCCESS, payload: '' };
};
