import { UserActions, UserState } from '../types/users';
import { LOGIN_USER_ERROR, LOGIN_USER_SUCCESS, LOGOUT_USER_SUCCESS } from '../const/user';
import { USER_AUTH_COMMON_TOKEN } from '../../utils/consts';

const initialState: UserState = {
  isLogin: localStorage.getItem(USER_AUTH_COMMON_TOKEN) || '' ? true : null,
  token: '',
  error: '',
};

export const userReducer = (state = initialState, action: UserActions) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return { ...state, isLogin: true, token: action.payload, error: '' };
    case LOGOUT_USER_SUCCESS:
      return { ...state, isLogin: false, token: '' };
    case LOGIN_USER_ERROR:
      return { ...state, isLogin: false, error: action.payload };
    default:
      return state;
  }
};
