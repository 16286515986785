import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

interface InputProps {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  background?: string;
  onClick?: () => void;
  onChange?: () => void;
  onBlur?: () => void;
  type?: 'button' | 'submit' | 'reset';
  rightIcon?: React.ReactNode;
}

const StyledInput = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  .label {
    font-size: 16px;
    font-weight: 400;
  }

  .input {
    width: 100%;
    height: 48px;
    border: 1px solid #ebebeb;
    outline: none;
    border-radius: 8px;
    padding: 12px 16px;
  }

  .inputRightIcon {
    padding: 12px 42px 12px 16px;
  }

  .withIcon {
    position: relative;
  }

  .right-icon {
    position: absolute;
    transform: translateY(-50%);
    right: 12px;
    top: 50%;
  }
`;

export const Input = ({ label, placeholder, rightIcon, ...props }: InputProps): JSX.Element => {
  const [field, meta] = useField(props);
  return (
    <StyledInput {...props}>
      {label && (
        <label className="label" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}

      {rightIcon ? (
        <div className="withIcon">
          <input className="input inputRightIcon" {...field} {...props} placeholder={placeholder} />
          {rightIcon && <div className="right-icon">{rightIcon}</div>}
        </div>
      ) : (
        <input className="input" {...field} {...props} placeholder={placeholder} />
      )}

      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </StyledInput>
  );
};
