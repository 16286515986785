import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { StyledModal } from './styled';
import { addNewBlock } from '../../api/shop';
import { BlockData } from './types';
import { Button, FileUploader, Input } from '../common';
import { ModalIconClose } from '../common/icons';

const validateSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
});

export const ModalAddNewBlock = (): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [imageKey, setImageKey] = React.useState<string>('');

  const initialValues: BlockData = {
    title: '',
    image: '',
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Button
        onClick={toggleModal}
        name="Legg til ny varegruppe"
        title="Add New Block"
        background="#00ADEF"
        color="white"
      />
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <div className="modalHeader">
          <div>Add New Block</div>
          <div className="iconButton" onClick={toggleModal}>
            <ModalIconClose />
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={async values => {
            const formValues = {
              ...values,
              image: `${imageKey}`,
            };

            await addNewBlock(formValues);
            await toggleModal();
            await location.reload();
          }}
        >
          {(formik: any) => {
            const { errors, touched, isSubmitting, handleSubmit, setFieldValue } = formik;

            React.useEffect(() => {
              setFieldValue('image', imageKey);
            }, [imageKey]);

            return (
              <Form className="col" onSubmit={handleSubmit}>
                <div className="modalBody">
                  <div className="col">
                    <Input name="title" label="Title" placeholder="f.e. Block" />
                    <div className="col">
                      <label className="label">Image </label>
                      <FileUploader name="image" imagePath={imageKey} setImageKey={setImageKey} />
                      {touched.image && errors.image ? <div className="error">{errors.image}</div> : null}
                    </div>
                  </div>
                </div>

                <div className="modalFooter">
                  <Button onClick={toggleModal} name="Cancel" color="#00ADEF" />
                  <Button type="submit" name="Save" background="#00ADEF" disabled={isSubmitting} color="white" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </StyledModal>
    </div>
  );
};
