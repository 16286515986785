import {
  ModalEvent,
  ModalForSavingData,
  ModalInfo,
  NavigateTo,
  ReloadPage,
  ToggleModalEvent,
  ToggleModalInfo,
} from '../types/modal';

export const changedEventData = (payload: boolean): ModalEvent => ({
  type: ModalForSavingData.CHANGED_EVENT_DATA,
  payload,
});

export const changedInfoData = (payload: boolean): ModalInfo => ({
  type: ModalForSavingData.CHANGED_INFO_DATA,
  payload,
});

export const toggleModalEvent = (payload: boolean): ToggleModalEvent => ({
  type: ModalForSavingData.TOGGLE_EVENT_MODAL,
  payload,
});

export const toggleModalInfo = (payload: boolean): ToggleModalInfo => ({
  type: ModalForSavingData.TOGGLE_INFO_MODAL,
  payload,
});

export const navigateToUrl = (payload: string): NavigateTo => ({
  type: ModalForSavingData.NAVIGATE_TO_URL,
  payload,
});

export const reloadPage = (payload: boolean): ReloadPage => ({
  type: ModalForSavingData.MODAL_RELOAD_PAGE,
  payload,
});
