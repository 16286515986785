import React, { useState } from 'react';
import { Button, ModalDelete } from '../common';
import { PageItem } from 'src/styled';
import ModalUpdateNotifications from './ModalUpdateNotifications';
import { removeNotifications, updateNotifications } from 'src/api/notifications';

interface notificationsItem {
  title?: string;
  text?: any;
  id: string;
  updateData: any;
  buttonText?: string;
  typeEdit: string;
  modalButtonUpdateContent: string;
  isArchive: boolean;
}

export const NotificationsItem = ({
  title,
  text,
  id,
  updateData,
  buttonText,
  typeEdit,
  modalButtonUpdateContent,
  isArchive,
}: notificationsItem) => {
  const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false);

  const removeItem = async (id: string) => {
    await removeNotifications(id).catch(err => console.log(err));
  };
  const pushNowNotifications = () => {
    updateNotifications({ id, sent: true })
      .then(() => {
        updateData((prev: any) => [...prev, 1]);
      })
      .catch(err => console.log(err));
  };
  return (
    <PageItem>
      <div className="item-header">{title}</div>
      <div>{text && new Date(text).toLocaleString().slice(0, -3)}</div>
      <div className="item-body">
        {buttonText && <Button onClick={pushNowNotifications} name={buttonText} background="#00ADEF" color="white" />}
        <Button onClick={() => setIsOpenModalUpdate(true)} name="Edit" color="#00ADEF" />
        <ModalDelete handleDelete={removeItem} id={id} />
      </div>
      <ModalUpdateNotifications
        updateData={updateData}
        id={id}
        isOpen={isOpenModalUpdate}
        setIsOpen={setIsOpenModalUpdate}
        partOfTitle={typeEdit}
        buttonContent={modalButtonUpdateContent}
        isArchive={isArchive}
      />
    </PageItem>
  );
};
