import React from 'react';
import Switch from 'react-switch';

interface switchButton {
  isChecked: boolean;
  onChange: (item: any) => void;
}

const SwitchToggle = ({ isChecked, onChange }: switchButton) => {
  return (
    <Switch
      onColor={'#00ADEF'}
      height={16}
      width={28}
      checkedIcon={false}
      uncheckedIcon={false}
      onChange={onChange}
      checked={isChecked}
    />
  );
};

export default SwitchToggle;
