import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { StyledModal } from './styled';
import { getOfferById, editOfferById } from '../../api/offers';
import { OfferData } from './types';
import { FileUploader, Button, Input } from '../common';
import { ModalIconClose } from '../common/icons';
import ModalUnsavedDataVariant from '../common/ModalUnsavedDataVarianWithoutRedux';

const validateSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  subtitle: Yup.string().required('Subtitle is required'),
  link: Yup.string().required('Link is required'),
  imagePath: Yup.string().required('Photo is required'),
});

export const EditOfferModal = (props: any): JSX.Element => {
  const { offerId } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenModalSave, setIsOpenModalSave] = React.useState(false);
  const [offer, setOffer] = React.useState<OfferData>({ title: '', subtitle: '', link: '', imagePath: '' });
  const [imageKey, setImageKey] = React.useState<string>('');
  const [isDirty, setIsDirty] = React.useState(false);

  if (!props.offerId) {
    return <></>;
  }

  const openModal = () => setIsOpen(true);
  const toggleModal = () => {
    if (isDirty) {
      setIsOpenModalSave(true);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const getOffer = async () => {
    const offer = await getOfferById(offerId);
    setOffer(offer);
  };

  React.useEffect(() => {
    if (isOpen) {
      getOffer();
    }
  }, [isOpen]);

  const initialValues: OfferData = {
    title: offer.title,
    subtitle: offer.subtitle,
    link: offer.link,
    imagePath: offer.imagePath,
  };

  return (
    <>
      <Button onClick={openModal} name="Edit" color="#00ADEF" />
      <ModalUnsavedDataVariant isOpen={isOpenModalSave} setIsOpen={setIsOpenModalSave} closeMainModal={setIsOpen} />
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <div className="modalHeader">
          <div>Edit Special Offer</div>
          <div className="iconButton" onClick={toggleModal}>
            <ModalIconClose />
          </div>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={async values => {
            const formValues = {
              ...values,
              imagePath: `${imageKey || offer.imagePath}`,
            };

            await editOfferById(offerId, formValues);
            await location.reload();
          }}
        >
          {formik => {
            const { handleSubmit, touched, errors, isSubmitting, dirty } = formik;

            React.useEffect(() => {
              setIsDirty(dirty);
            }, [dirty]);

            return (
              <Form className="col" onSubmit={handleSubmit}>
                <div className="modalBody">
                  <Input name="title" label="Title" placeholder="f.e. Lady Gaga" />
                  <Input name="subtitle" label="Subtitle" placeholder="f.e. Lady Gaga" />
                  <Input name="link" label="Link" placeholder="http://google.com" />

                  <div className="col">
                    <label className="label">Photo</label>
                    <FileUploader imagePath={offer?.imagePath} setImageKey={setImageKey} />
                    {touched.imagePath && errors.imagePath ? <div className="error">{errors.imagePath}</div> : null}
                  </div>
                </div>

                <div className="modalFooter">
                  <Button onClick={toggleModal} name="Cancel" color="#00ADEF" type="button" />
                  <Button type="submit" name="Save" background="#00ADEF" color="white" disabled={isSubmitting} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </StyledModal>
    </>
  );
};
