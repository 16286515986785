import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { getBlockById, editBlockById } from '../../api/shop';
import { Button, FileUploader, Input } from '../common';
import { StyledModal } from './styled';
import { BlockData } from './types';
import { ModalIconClose } from '../common/icons';
import ModalUnsavedDataVariant from '../common/ModalUnsavedDataVarianWithoutRedux';

const validateSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  image: Yup.string().required('Image is required'),
});

export const ModalEditBlock = (props: any): JSX.Element => {
  const { blockId } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenSeveModal, setIsOpenSaveModal] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [block, setBlock] = React.useState<BlockData>({ title: '', image: '' });
  const [imageKey, setImageKey] = React.useState<string>('');

  if (!blockId) {
    return <></>;
  }

  const openModal = () => setIsOpen(true);
  const toggleModal = () => {
    if (isDirty) {
      setIsOpenSaveModal(true);
    } else {
      setIsOpen(false);
    }
  };

  const getBlock = async () => {
    const block = await getBlockById(blockId);
    setBlock(block[0] || block); // change if API changed
  };

  React.useEffect(() => {
    if (isOpen) {
      getBlock();
    }
  }, [isOpen]);

  const initialValues: BlockData = {
    title: block.title,
    image: block.image,
  };

  return (
    <>
      <Button onClick={openModal} name="Edit" color="#00ADEF" />
      <ModalUnsavedDataVariant isOpen={isOpenSeveModal} setIsOpen={setIsOpenSaveModal} closeMainModal={setIsOpen} />
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <div className="modalHeader">
          <div>Edit Block</div>
          <div className="iconButton" onClick={toggleModal}>
            <ModalIconClose />
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={async values => {
            const formValues = {
              ...values,
              image: `${imageKey || block.image}`,
            };

            await editBlockById(blockId, formValues);
            await location.reload();
          }}
        >
          {(formik: any) => {
            const { isSubmitting, handleSubmit, touched, errors, dirty } = formik;
            React.useEffect(() => {
              setIsDirty(dirty);
            }, [dirty]);
            return (
              <Form className="col" onSubmit={handleSubmit}>
                <div className="modalBody">
                  <div className="col">
                    <Input name="title" label="Title" placeholder="f.e. Block" />

                    <label className="label">Image</label>
                    <FileUploader name="image" imagePath={block?.image} setImageKey={setImageKey} />
                    {touched.image && errors.image ? <div className="error">{errors.image}</div> : null}
                  </div>
                </div>

                <div className="modalFooter">
                  <Button onClick={toggleModal} name="Cancel" color="#00ADEF" type="button" />
                  <Button type="submit" name="Save" background="#00ADEF" color="white" disabled={isSubmitting} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </StyledModal>
    </>
  );
};
