import React from 'react';

const LoaderBootstrap = () => {
  return (
    <div className="spinner-border" role="status">
      <span className="sr-only" />
    </div>
  );
};

export default LoaderBootstrap;
