export enum ModalForSavingData {
  CHANGED_EVENT_DATA = 'CHANGED_EVENT_DATA',
  CHANGED_INFO_DATA = 'CHANGED_INFO_DATA',
  TOGGLE_EVENT_MODAL = 'TOGGLE_EVENT_MODAL',
  TOGGLE_INFO_MODAL = 'TOGGLE_INFO_MODAL',
  NAVIGATE_TO_URL = 'NAVIGATE_TO_URL',
  MODAL_RELOAD_PAGE = 'MODAL_RELOAD_PAGE',
}

export interface ModalEvent {
  type: ModalForSavingData.CHANGED_EVENT_DATA;
  payload: boolean;
}

export interface ModalInfo {
  type: ModalForSavingData.CHANGED_INFO_DATA;
  payload: boolean;
}

export interface ToggleModalEvent {
  type: ModalForSavingData.TOGGLE_EVENT_MODAL;
  payload: boolean;
}

export interface ToggleModalInfo {
  type: ModalForSavingData.TOGGLE_INFO_MODAL;
  payload: boolean;
}

export interface NavigateTo {
  type: ModalForSavingData.NAVIGATE_TO_URL;
  payload: string;
}
export interface ReloadPage {
  type: ModalForSavingData.MODAL_RELOAD_PAGE;
  payload: boolean;
}
export type ModalSavingData = ModalEvent | ModalInfo | ToggleModalEvent | ToggleModalInfo | NavigateTo | ReloadPage;

export interface initialModalData {
  isChangedEventData: boolean;
  isChangedInfoData: boolean;
  isOpenModalEvent: boolean;
  isOpenModalInfo: boolean;
  navigateTo: string;
  reloadPage: boolean;
}
