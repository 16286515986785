import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

interface InputProps {
  name: string;
  label?: string;
  placeholder?: string;
  background?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
}

const StyledTextarea = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .label {
    font-size: 16px;
    font-weight: 400;
  }

  .textarea {
    resize: none;
    display: flex;
    align-items: start;
    width: 100%;
    min-height: 200px;
    border: 1px solid #ebebeb;
    outline: none;
    border-radius: 8px;
    padding: 12px 16px;
  }
`;

export const Textarea = ({ label, ...props }: any): JSX.Element => {
  const [field, meta] = useField(props);
  return (
    <StyledTextarea {...props}>
      {label && (
        <label className="label" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <textarea className="textarea" as="textarea" {...field} {...props} />
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </StyledTextarea>
  );
};
