import { axiosPrivate } from './axios';

export const fetchOffers = async (query: string) => {
  const { data } = await axiosPrivate.get(`/offers?search=${query}`);
  return data;
};

export const getOfferById = async (id: string) => {
  const { data } = await axiosPrivate.get(`/offers/${id}`);
  return data;
};

export const addNewOffer = async (data: any) => {
  await axiosPrivate.post('/offers', data);
};

export const editOfferById = async (id: string, data: any) => {
  await axiosPrivate.patch(`/offers/${id}`, data);
};

export const deleteOfferById = async (id: string) => {
  await axiosPrivate.delete(`/offers/${id}`);
};
