import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { eventGetData } from 'src/api/event';
import { getAllArtist, getSchedules } from 'src/api/event-schedule';
import LoaderBootstrap from 'src/components/common/LoaderBootstrap';
import { NotificationsLogo, PersonLogo, TimeLogo } from './icons';
import ModalAddNewItem from './ModalAddNewItem';
import ModalUpdateNewItem from './ModalUpdateItem';
import TableItem from './TableItem';

interface eventScheduleData {
  time: string;
  artist: {
    name: string;
  };
  id: string;
  stage: string;
}

export const EventSchedule = () => {
  const [scheduleData, setScheduleData] = useState<eventScheduleData[] | undefined>(undefined);
  const [updateData, setUpdateData] = useState([]);
  const [artists, setArtist] = useState([]);
  const [updateIdItem, setUpdateIdItem] = useState('');
  const [eventTitle, setEventTitle] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    eventGetData()
      .then(item => {
        setEventTitle(item.data[0].title);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    getSchedules()
      .then(item => {
        setScheduleData(item.data);
      })
      .catch(err => console.log(err));
    getAllArtist()
      .then(item => {
        setArtist(item.data);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    getSchedules()
      .then(item => {
        setScheduleData(item.data);
      })
      .catch(err => console.log(err));
  }, [updateData]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const closeUpdateModal = () => setShowUpdateModal(prev => !prev);
  const closeAddModal = () => setShowAddModal(prev => !prev);
  return (
    <div className="EventSchedule">
      <div className="EventSchedule-container">
        <div className="EventSchedule-container__breadcrumbs">
          <div onClick={() => navigate('/')} className="breadcrumbs-item">
            Om LS
            <div className="breadcrumbs-item__arrow" />
          </div>
          <div className="breadcrumbs-item">Program</div>
        </div>
        <div className="EventSchedule-main">
          <div className="EventSchedule-main__title">{eventTitle} Program</div>
          <div className="EventSchedule-main__table">
            <div className="EventSchedule-main__table-header">
              <div className="table-header_column-time">
                <TimeLogo />
                Time
              </div>
              <div className="table-header_column-artist">
                <PersonLogo />
                Artist
              </div>
              <div className="table-header_column-scene">
                <NotificationsLogo />
                Scene
              </div>
              <div className="table-item__button"></div>
            </div>
            <div className="EventSchedule-main__table-body">
              {scheduleData?.length === 0 && <div className={'schedule-data__is-empting'}>No schedule yet...</div>}
              {scheduleData !== undefined ? (
                scheduleData.map(item => (
                  <TableItem
                    updateData={setUpdateData}
                    id={item.id}
                    showModal={closeUpdateModal}
                    time={item.time}
                    artist={item.artist.name}
                    scene={item.stage}
                    key={item.id}
                    setUpdateIdItem={setUpdateIdItem}
                  />
                ))
              ) : (
                <div className="loader-center">
                  <LoaderBootstrap />
                </div>
              )}
            </div>
          </div>
          <div onClick={closeAddModal} className="EventSchedule-main__button-add" title="Add schedule entry">
            Legg til ny hendelse
          </div>
        </div>
      </div>
      <ModalAddNewItem updateData={setUpdateData} artists={artists} show={showAddModal} closeModal={closeAddModal} />
      <ModalUpdateNewItem
        updateData={setUpdateData}
        updateIdItem={updateIdItem}
        artists={artists}
        show={showUpdateModal}
        closeModal={closeUpdateModal}
      />
    </div>
  );
};
