//routes
export const ARTISTS_ROUTE = '/artists';
export const ARTISTS_DETAIL_ROUTE = ':id';
export const LOGIN_ROUTE = '/login';
export const HOME_ROUTE = '/';
export const BLOG_ROUTE = '/blog';
export const SHOP_BLOCKS_ROUTE = '/shop';
export const SHOP_GOODS_ROUTE = '/shop/:id';
export const INFO_MAIN_ROUTE = '/info';
export const OFFERS_ROUTE = '/offers';
export const CONTACTS_ROUTE = '/contacts';
export const EVENT_SHEDULE_ROUTE = '/event/shedule';
export const EVENT_MAP_ROUTE = '/event/map';
export const EVENT_ARTIST_ROUTE = '/event/artists';
export const NOTIFICATIONS_CENTER_ROUTE = '/notifications/center';

//token

export const USER_AUTH_REFRESH_TOKEN = '@LGA/USER_AUTH_REFRESH_TOKEN';
export const USER_AUTH_COMMON_TOKEN = '@LGA/USER_AUTH_COMMON_TOKEN';

export const BASE_API_URL = 'https://api.lga-administration.com';
export const AMAZON_IMAGE_URL = 'https://file-storage-dev.s3.eu-north-1.amazonaws.com/';
