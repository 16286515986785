import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form } from 'react-bootstrap';

import { FileUploader } from '../common';

export interface Artist {
  id?: number;
  name?: string;
  description?: string;
  presentationOrder?: string;
  imagePath?: string;
}

const ArtistDetail = (): any => {
  const { id } = useParams();
  const [artist, setArtist] = useState<Artist>({
    id: 0,
    name: '',
    description: '',
    presentationOrder: '',
    imagePath: '',
  });
  const navigate = useNavigate();

  const mock = [
    { id: 0, name: 'One', description: 'One description' },
    { id: 1, name: 'Two', description: 'Two description' },
    { id: 2, name: 'Three', description: 'Three description' },
    { id: 3, name: 'Four', description: 'Four description' },
    { id: 4, name: 'Five', description: 'Five description' },
    { id: 5, name: 'Six', description: 'Six description' },
    { id: 6, name: 'Seven', description: 'Seven description' },
    { id: 7, name: 'Eight', description: 'Eight description' },
    { id: 8, name: 'Nine', description: 'Nine description' },
  ];
  //const temp: any = mock.filter((el: any) => el.id === id);
  useEffect(() => {
    if (id !== 'add') {
      setArtist(mock[id as any]);
    }
  }, []);

  const [testData, setTestData] = useState<any>(mock);

  const handleSubmit = () => {
    //if add
    //if update
    setTestData([...testData, artist]);
    setArtist({ name: '', description: '' });
    console.log(testData);
  };

  const handleCancel = () => {
    setArtist({ name: '', description: '' });
    navigate(-1);
  };

  return (
    <Container fluid>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Artist</Form.Label>
          <Form.Control
            placeholder="Artist"
            value={artist.name}
            onChange={e => setArtist({ ...artist, name: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Presentation Order</Form.Label>
          <Form.Control
            placeholder="Presentation Order"
            value={artist.presentationOrder}
            onChange={e => setArtist({ ...artist, presentationOrder: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={artist.description}
            onChange={e => setArtist({ ...artist, description: e.target.value })}
          />
        </Form.Group>
        <FileUploader />
        <Button variant="outline-primary" onClick={handleSubmit}>
          {id === 'add' ? <span>Add</span> : <span>Save</span>}
        </Button>
        <Button variant="outline-danger" onClick={handleCancel}>
          Cancel
        </Button>
      </Form>

      {/* {testData.map((el) => (
        <p>{el.name}</p>
      ))} */}
    </Container>
  );
};

export default ArtistDetail;
