import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { createSchedule } from 'src/api/event-schedule';
import UniqueInput from 'src/components/common/UniqueInput';
import { Button } from 'src/components/common';
import DateTimesPicker from 'src/components/common/DatePicker';
import { StyledModal } from './styled';
import { ModalIconClose } from 'src/components/common/icons';
interface modalAddInterface {
  show: boolean;
  closeModal: () => any;
  artists: any;
  updateData: (item: any) => void;
}

const ModalAddNewItem = ({ show, closeModal, artists, updateData }: modalAddInterface) => {
  const [currentId, setCurrentId] = useState('');
  const [currentName, setCurrentName] = useState('');
  const [scene, setScene] = useState('');
  const [time, setTime] = useState<any>(new Date());
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [isValid, setIsValid] = useState<null | boolean>(null);

  const addSchedule = () => {
    if (
      currentId?.length !== 0 &&
      time?.length !== 0 &&
      scene?.length !== 0 &&
      minutes?.length !== 0 &&
      hours?.length !== 0
    ) {
      createSchedule({ artistId: currentId, time, stage: scene })
        .then(() => {
          closeModal();
          setCurrentId('');
          setTime(new Date());
          setScene('');
          setCurrentName('');
          setHours('');
          setMinutes('');
          setIsValid(null);
          updateData((prev: any) => [...prev, 1]);
        })
        .catch(err => console.log(err));
    } else {
      setIsValid(false);
    }
  };

  return (
    <StyledModal isOpen={show}>
      <div className="modalHeader">
        <div>Add New Schedule Entry</div>
        <div onClick={closeModal} className="iconButton">
          <ModalIconClose />
        </div>
      </div>
      <div className="modalBody">
        <div className="modal__title-time">Time</div>
        <DateTimesPicker
          hours={hours}
          setHours={setHours}
          minutes={minutes}
          setMinutes={setMinutes}
          setDate={setTime}
          initialDate={time}
        />
        <div className="modal__title-artist">Artist</div>
        <DropdownButton
          className={currentName.length > 0 ? 'active' : 'unactive'}
          id="dropdown-item-button"
          title={currentName.length > 0 ? currentName : 'Choose the artist...'}
        >
          {artists.map((item: any) => (
            <Dropdown.Item
              onClick={(e: any) => {
                setCurrentId(e.target.value);
                setCurrentName(e.target.name);
              }}
              key={item.id}
              as="button"
              value={item.id}
              name={item.name}
            >
              {item.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <div className="modal__title-scene">Scene</div>
        <UniqueInput item={scene} setItem={setScene} style={{ width: '100%' }} placeStr="f.e. Lorem" type={0} />
        {isValid === false && <div className="error-line">All fields must be filled</div>}
      </div>
      <div className="modalFooter">
        <div className="button-cancel" onClick={closeModal}>
          Cancel
        </div>
        <Button onClick={addSchedule} name="Save" />
      </div>
    </StyledModal>
  );
};

export default ModalAddNewItem;
