import React, { useEffect, useState } from 'react';
import { mapImageUpload, mapAddImage, mapGetData, mapImageDelete } from 'src/api/event-map';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { HOME_ROUTE } from 'src/utils/consts';
import LoaderBootstrap from '../../common/LoaderBootstrap';
interface handleError {
  isActive: boolean | null;
  message: string;
  isSuccess: boolean;
}

export const EventMap = () => {
  const [errMessage, setErrMessage] = useState<handleError>({
    isActive: null,
    message: '',
    isSuccess: false,
  });
  const [files, setFiles] = useState<any>([]);
  const removeImage = async () => {
    await mapImageDelete(files[0].id)
      .then(() => {
        setFiles([]);
        setErrMessage({
          isActive: true,
          message: 'Success removed image',
          isSuccess: true,
        });
        setTimeout(() => {
          setErrMessage({} as handleError);
        }, 5000);
      })
      .catch(err => {
        setErrMessage({
          isActive: true,
          message: 'Problem with remove image, please reload page',
          isSuccess: false,
        });
        setTimeout(() => {
          setErrMessage({} as handleError);
        }, 5000);
        console.log(err);
      });
    await mapGetData()
      .then(item => {
        if (item.data.length === 1) {
          const { id, imagePath } = item.data[0];
          setFiles(() => [
            {
              id,
              path: imagePath,
              preview: `https://file-storage-dev.s3.eu-north-1.amazonaws.com/${imagePath}`,
              name: imagePath,
            },
          ]);
        }
      })
      .catch(err => {
        setErrMessage({
          isActive: true,
          message: 'Problem with loading data from server, please reload page',
          isSuccess: false,
        });
        setTimeout(() => {
          setErrMessage({} as handleError);
        }, 5000);
        console.log(err);
      });
  };
  const [isLoadingFile, setIsLoadingFile] = useState<boolean | null>(null);
  const [imageKey, setImageKey] = useState('');

  useEffect(() => {
    const getImageFromServer = async () => {
      await mapGetData()
        .then(item => {
          if (item.data.length === 1) {
            const { id, imagePath } = item.data[0];
            setFiles(() => [
              {
                id,
                path: imagePath,
                preview: `https://file-storage-dev.s3.eu-north-1.amazonaws.com/${imagePath}`,
                name: imagePath,
              },
            ]);
          }
        })
        .catch(err => {
          setErrMessage({
            isActive: true,
            message: 'Problem with loading data from server, please reload page',
            isSuccess: false,
          });
          setTimeout(() => {
            setErrMessage({} as handleError);
          }, 5000);
          console.log(err);
        });
    };
    getImageFromServer();
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFile => {
      if (files.length === 0) {
        setFiles(
          acceptedFile.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              local: true,
            })
          )
        );
      } else {
        setErrMessage({ isActive: true, message: 'To upload a new photo, delete the previous one', isSuccess: false });
        setTimeout(() => {
          setErrMessage({} as handleError);
        }, 5000);
      }
    },
  });

  const getIamgePath = async () => {
    const file = files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      setIsLoadingFile(true);
      await mapImageUpload(formData)
        .then(item => {
          setImageKey(item.data.Key);
          return item;
        })
        .catch(err => {
          console.log(err);
          setErrMessage({
            isActive: true,
            message: 'Problem with loading image to the severe, please reload page',
            isSuccess: false,
          });
          setTimeout(() => {
            setErrMessage({} as handleError);
          }, 5000);
        });
    }
  };

  const onSaveImage = (key: string) => {
    mapAddImage(key)
      .then(item => {
        setIsLoadingFile(false);
        setErrMessage({ isActive: true, message: 'Success loading image to server', isSuccess: true });
        setTimeout(() => {
          setErrMessage({} as handleError);
        }, 5000);
        window.location.reload();
        return item;
      })
      .catch(err => {
        if (err.response.status === 409) {
          setErrMessage({
            isActive: true,
            message: 'To upload a new photo, delete the previous one',
            isSuccess: false,
          });
          setTimeout(() => {
            setErrMessage({} as handleError);
          }, 5000);
        }
        setIsLoadingFile(false);
        setErrMessage({ isActive: true, message: err.response.message, isSuccess: false });
        setTimeout(() => {
          setErrMessage({} as handleError);
        }, 5000);
      });
  };

  useEffect(() => {
    if (files.length > 0 && files[0].local) {
      getIamgePath();
    }
  }, [files]);
  useEffect(() => {
    if (imageKey.length > 0) {
      onSaveImage(imageKey);
    }
  }, [imageKey]);

  const previewImages = files.map((file: any, id: number) => (
    <img key={id} className="EventMap-img__data-uploaded_by-admin" src={file.preview} alt="preview image" />
  ));

  return (
    <div className="EventMap">
      <div className="EventMap-container">
        <div className="EventMap-breadcrumbs__container">
          <Link to={HOME_ROUTE} className="EventMap-breadcrumbs__container-item_1">
            Om LS
            <div className="EventMap-breadcrumbs__container-arrow" />
          </Link>
          <div className="EventMap-breadcrumbs__container-item_2" title="Map">
            Kart
          </div>
        </div>
        <div className="EventMap-main">
          <div className="EventMap-main__title">Kart</div>
          <div className="EventMap-main__content">
            <div className="EventMap-main__content-file-uploader">
              <div {...getRootProps()} className="block-upload">
                <input {...getInputProps()} />
                {isLoadingFile !== true ? (
                  <div className="Preview Image">{previewImages}</div>
                ) : (
                  <div className="image-loader_container">
                    <LoaderBootstrap />
                  </div>
                )}
                <div className="block-upload__image" />
                <div className="block-upload__container-text">
                  <div className="block-upload__container-text_part-1">Click to Upload File</div>
                  <div className="block-upload__container-text_part-2">or Drag and drop files here</div>
                </div>
              </div>
            </div>
            {errMessage.isActive && (
              <div className={errMessage.isSuccess ? 'EventMap-success' : 'EventMap-error'}>{errMessage.message}</div>
            )}
            {files[0] && (
              <div className="EventMap-img__data">
                <div className="EventMap-img__data_item-1">
                  <div className="EventMap-img__data-jpg_logo" />
                  <div className="EventMap-img__data-name">{files[0].path}</div>
                </div>
                <div className="EventMap-img__data_item-2">
                  <div onClick={removeImage} className="EventMap-img__data-remove_logo" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
