import { axiosPrivate } from './axios';

export interface createScheduleInteeface {
  artistId: string;
  time: string;
  stage: string;
  id?: string;
}

export const getAllArtist = () => axiosPrivate.get('/artists');

export const createSchedule = ({ artistId, time, stage }: createScheduleInteeface) =>
  axiosPrivate.post('/schedules', { artistId, time, stage });

export const getSchedules = () => axiosPrivate.get(`/schedules/admin`);
export const getOneSchedule = (id: string) => axiosPrivate.get(`/schedules/${id}`);

export const deleteSchedule = (id: string) => axiosPrivate.delete(`/schedules/${id}`);

export const updateSchedule = ({ id, time, artistId, stage }: createScheduleInteeface) =>
  axiosPrivate.patch(`/schedules/${id}`, { artistId, time, stage });
