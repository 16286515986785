import React, { useState } from 'react';
import { createNotifications } from 'src/api/notifications';
import DateTimesPicker from '../common/DatePicker';
import SwitchToggle from '../common/SwitchToggle';
import UniqueInput from '../common/UniqueInput';
import { ModalIconClose } from './icons';
import { StyledModal } from './styled';

interface modalAddNotifications {
  isOpen: boolean;
  setIsOpen: (item: any) => void;
  updateData: any;
}

const ModalAddNotifications = ({ isOpen, setIsOpen, updateData }: modalAddNotifications) => {
  const [initialDate, setInitialDate] = useState(new Date());
  const [minutes, setMinutes] = useState('');
  const [hours, setHours] = useState('');
  const [isTimeBase, setIsTimeBase] = useState(false);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [link, setLink] = useState('');
  const [isCorrect, setIsCorrect] = useState<boolean | undefined>(undefined);

  const togglePopup = () => setIsOpen((prev: boolean) => !prev);

  const createData = () => {
    if (title.length !== 0 && subtitle.length !== 0 && link.length !== 0) {
      createNotifications({ title, subtitle, dateTime: initialDate, timeBase: isTimeBase, link })
        .then(() => {
          setInitialDate(new Date());
          setMinutes('');
          setHours('');
          setIsTimeBase(false);
          setTitle('');
          setSubtitle('');
          setLink('');
          updateData((prev: any) => [...prev, 1]);
          setIsOpen(false);
        })
        .catch(err => console.log(err));
    } else {
      setIsCorrect(false);
    }
  };

  return (
    <StyledModal isOpen={isOpen}>
      <div className="modalHeader">
        <div>Add New Block</div>
        <div className="iconButton" onClick={togglePopup}>
          <ModalIconClose />
        </div>
      </div>
      <div className="modalBody">
        <div className="modalBody-container">
          <div className="modalBody-first_title">Title</div>
          <UniqueInput item={title} setItem={setTitle} style={{ width: '100%' }} placeStr="f.e. Offer 1" type={0} />
          <div className="modalBody-title">Subtitle</div>
          <UniqueInput
            item={subtitle}
            setItem={setSubtitle}
            style={{ width: '100%' }}
            placeStr="f.e. Offer 1"
            type={0}
          />
          <div className="modalBody-title">Link</div>
          <UniqueInput item={link} setItem={setLink} style={{ width: '100%' }} placeStr="f.e. Offer 1" type={0} />
          <div className="modalBody-time_based">
            Time-based
            <SwitchToggle onChange={setIsTimeBase} isChecked={isTimeBase} />
          </div>
          {isTimeBase && (
            <div className="modalBody-time_notifications">
              Time Of The Notification
              <DateTimesPicker
                hours={hours}
                setHours={setHours}
                minutes={minutes}
                setMinutes={setMinutes}
                initialDate={initialDate}
                setDate={setInitialDate}
              />
            </div>
          )}
        </div>
      </div>
      <div className="modalFooter">
        {isCorrect === false && <div className="modalFooter-error">All fields must be filled...</div>}
        <div onClick={togglePopup} className="modalFooter-button__cancel">
          Cancel
        </div>
        <div onClick={createData} className="modalFooter-button__save">
          Save
        </div>
      </div>
    </StyledModal>
  );
};

export default ModalAddNotifications;
