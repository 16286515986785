import AppRouter from './components/router/AppRouter';
import { ModalProvider } from 'styled-react-modal';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';

function App() {
  return (
    <BrowserRouter>
      <ModalProvider>
        <AppRouter />
      </ModalProvider>
    </BrowserRouter>
  );
}

export default App;
