import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalAddNewBlock, ModalEditBlock } from '.';
import { deleteBlockById, fetchBlocks } from '../../api/shop';
import { SearchInput, Button, ModalDelete } from '../common';
import { Page, PageItem } from 'src/styled';
import { BlockData } from './types';

export const Blocks = () => {
  const navigate: any = useNavigate();
  const [searchInput, setSearchInput] = React.useState('');
  const [blocks, setBlocks] = React.useState<BlockData[]>([]);

  const getBlocks = async () => {
    const blocks = await fetchBlocks(searchInput);
    setBlocks(blocks);
  };

  const filterDataByTitle = (data: any, title: string): any => {
    return data.filter((item: any) => {
      if (item.title.toLowerCase().includes(title.toLowerCase())) {
        return item;
      }
    });
  };

  React.useEffect(() => {
    getBlocks();
  }, [deleteBlockById]);

  if (!blocks) return null;

  const handleSearch = React.useCallback(() => {
    getBlocks();
  }, [searchInput]);

  const handleDelete = async (blockId: string) => {
    await deleteBlockById(blockId);
  };

  return (
    <Page>
      <div className="breadCrumbs">Kjøp</div>
      <div className="pageTitle">Kjøp</div>
      <div className="header">
        <SearchInput value={searchInput} onEnter={handleSearch} onInput={(value: string) => setSearchInput(value)} />
        <ModalAddNewBlock />
      </div>
      <div className="listFlex">
        {filterDataByTitle(blocks, searchInput).length ? (
          filterDataByTitle(blocks, searchInput)?.map((item: BlockData, index: number) => {
            return (
              <PageItem key={index}>
                <div className="item-header">{item.title}</div>
                <div className="item-body">
                  <Button onClick={() => navigate(`/shop/${item.id}`)} name="View" background="#00ADEF" color="white" />
                  <ModalEditBlock blockId={item.id} />
                  <ModalDelete handleDelete={handleDelete} id={`${item.id}`} />
                </div>
              </PageItem>
            );
          })
        ) : (
          <div className="message-no_items">Kjøp tom...</div>
        )}
      </div>
    </Page>
  );
};
