import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { StyledModal } from './styled';
import { DataType } from './types';
import { FileUploader, Button, Input, Textarea } from '../common';
import { MapIcon, ModalIconClose } from '../common/icons';
import SimpleBar from 'simplebar-react';
import ModalUnsavedDataVariant from '../common/ModalUnsavedDataVarianWithoutRedux';

const validateSchema = Yup.object({
  address: Yup.string().required('Address is required'),
  detail: Yup.string().required('Details is required'),
  imagePath: Yup.string().required('Photo is required'),
});

export const EditModal = (props: any): JSX.Element => {
  const { getData, editData, modalName } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [data, setData] = React.useState<DataType>({ id: '', address: '', detail: '', imagePath: '' });
  const [imageKey, setImageKey] = React.useState<string>('');
  const [modalHeight, setModalHeight] = React.useState<string | number>(560);
  const [isOpenSaveModal, setIsOpenSaveModal] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  React.useEffect(() => {
    if (isOpen) {
      if (window.innerHeight - 200 <= 600) {
        setModalHeight(window.innerHeight * 0.55);
      } else {
        setModalHeight('unset');
      }
    }
  }, [isOpen]);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const toggleModal = () => {
    if (isDirty) {
      setIsOpenSaveModal(true);
    } else {
      closeModal();
    }
  };

  const getOffer = async () => {
    const data = await getData();
    const { id, detail, imagePath, address } = data[0];
    setData({ id, address, detail, imagePath });
  };

  if (!modalName) return <></>;

  React.useEffect(() => {
    if (isOpen) {
      getOffer();
    }
  }, [isOpen]);

  const initialValues: DataType = {
    address: data.address,
    detail: data.detail,
    imagePath: data.imagePath,
  };

  return (
    <>
      <Button onClick={openModal} name="Edit" color="#00ADEF" />
      <ModalUnsavedDataVariant isOpen={isOpenSaveModal} setIsOpen={setIsOpenSaveModal} closeMainModal={setIsOpen} />
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <div className="modalHeader">
          <div>Edit {modalName}</div>
          <div className="iconButton" onClick={toggleModal}>
            <ModalIconClose />
          </div>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={async values => {
            const formValues = {
              ...values,
              imagePath: `${imageKey || data.imagePath}`,
            };
            console.log(data);
            await editData(data.id, formValues);
            await location.reload();
          }}
        >
          {formik => {
            const { handleSubmit, touched, errors, isSubmitting, dirty } = formik;
            React.useEffect(() => {
              setIsDirty(dirty);
            }, [dirty]);

            return (
              <Form className="col" onSubmit={handleSubmit}>
                <div className="modalBody">
                  <SimpleBar autoHide={false} style={{ maxHeight: modalHeight, padding: '20px', paddingRight: '24px' }}>
                    <Input name="address" label="Address" placeholder="Type here" rightIcon={<MapIcon />} />

                    <div className="col" style={{ marginBottom: '24px' }}>
                      <label className="label">Photo</label>
                      <FileUploader imagePath={data?.imagePath} setImageKey={setImageKey} />
                      {touched.imagePath && errors.imagePath ? <div className="error">{errors.imagePath}</div> : null}
                    </div>

                    <Textarea name="detail" label="Details" placeholder="Type here" />
                  </SimpleBar>
                </div>

                <div className="modalFooter">
                  <Button onClick={toggleModal} name="Cancel" color="#00ADEF" type={'button'} />
                  <Button type="submit" name="Save" background="#00ADEF" color="white" disabled={isSubmitting} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </StyledModal>
    </>
  );
};
