import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { StyledModal } from './styled';
import { addNewOffer } from '../../api/offers';
import { OfferData } from './types';
import { FileUploader, Button, Input } from '../common';
import { ModalIconClose } from '../common/icons';

const validateSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  subtitle: Yup.string().required('Subtitle is required'),
  link: Yup.string().required('Link is required'),
  imagePath: Yup.string().required('Photo is required'),
});

export const AddOfferModal = (): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [imageKey, setImageKey] = React.useState<string>('');

  const initialValues: OfferData = {
    title: '',
    subtitle: '',
    link: '',
    imagePath: '',
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Button onClick={toggleModal} name="Legg til ny sak" title="Add New Offer" background="#00ADEF" color="white" />
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <div className="modalHeader">
          <div title="Add New Offer">Legg til ny sak</div>
          <div className="iconButton" onClick={toggleModal}>
            <ModalIconClose />
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={async values => {
            const formValues = {
              ...values,
              imagePath: `${imageKey}`,
            };
            await addNewOffer(formValues);
            await toggleModal();
            await location.reload();
          }}
        >
          {formik => {
            const { errors, touched, setFieldValue, isSubmitting, handleSubmit } = formik;

            React.useEffect(() => {
              setFieldValue('imagePath', imageKey);
            }, [imageKey]);

            return (
              <Form className="col" onSubmit={handleSubmit}>
                <div className="modalBody">
                  <Input name="title" label="Title" placeholder="f.e. Lady Gaga" />
                  <Input name="subtitle" label="Subtitle" placeholder="f.e. Lady Gaga" />
                  <Input name="link" label="Link" placeholder="http://google.com" />

                  <div className="col">
                    <label className="label">Photo</label>
                    <FileUploader name="imagePath" imagePath={imageKey || ''} setImageKey={setImageKey} />
                    {touched.imagePath && errors.imagePath ? <div className="error">{errors.imagePath}</div> : null}
                  </div>
                </div>

                <div className="modalFooter">
                  <Button onClick={toggleModal} name="Cancel" color="#00ADEF" />
                  <Button type="submit" name="Save" background="#00ADEF" disabled={isSubmitting} color="white" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </StyledModal>
    </div>
  );
};
