import React from 'react';
import ReactQuill from 'react-quill';
import { Field } from 'formik';
import styled from 'styled-components';
import useQuillModule from 'src/hooks/useQuillModule';
import 'react-quill/dist/quill.snow.css';

const StyledEditor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .label {
    font-size: 16px;
    font-weight: 400;
  }
`;

const StyledReactQuill = styled(ReactQuill)`
  .ql-toolbar {
    border-radius: 8px 8px 0 0;
    border: 1px solid #ebebeb;
  }

  .ql-container {
    font-family: inherit;
    font-size: inherit;
    border-radius: 0 0 8px 8px;
    border: 1px solid #ebebeb;
    min-height: 150px;
    max-height: 150px;
    overflow-y: auto;
  }
`;

export const Editor = (props: any) => {
  const { label, name, ...rest } = props;

  const { formats, modules, onBlur, onFocus } = useQuillModule();

  return (
    <StyledEditor>
      {label && (
        <label className="label" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}

      <Field name={name} {...rest}>
        {({ form, field }: any) => {
          const { setFieldValue } = form;
          const { value } = field;

          return (
            <StyledReactQuill
              theme="snow"
              {...field}
              {...rest}
              name={name}
              onBlur={onBlur}
              onFocus={onFocus}
              modules={modules}
              formats={formats}
              value={value}
              onChange={value => {
                setFieldValue(name, value === '<p><br></p>' ? '' : value.toString());
              }}
            />
          );
        }}
      </Field>
    </StyledEditor>
  );
};
