import React from 'react';
import Modal from 'styled-react-modal';
import { ModalIconClose } from './icons';
import { Button } from '.';
export const StyledModal = Modal.styled`
  font-family: Transat, sans-serif;
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 16px;
  
  .modalHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 18px;
    font-weight: 700;  
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid #ebebeb;
  }

  .modalBody {
    display: flex;
    width: 100%;
    padding: 20px;
    justify-content: center;
    &-content {
        width: 280px;
        font-size: 20px;
    }
  }

  .modalFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    border-top: 1px solid #ebebeb;
  }
  .iconButton {
    cursor: pointer;
    font-weight: 400;
  }

`;

interface ModalEvent {
  isOpen: boolean;
  setIsOpen: any;
  closeMainModal: any;
}

const ModalUnsavedDataVariant = ({ isOpen, setIsOpen, closeMainModal }: ModalEvent): JSX.Element => {
  const closePopup = () => setIsOpen(false);
  const agreeCloseModal = () => {
    closePopup();
    closeMainModal(false);
  };
  return (
    <StyledModal isOpen={isOpen}>
      <div className="modalHeader">
        <div onClick={closePopup} className="iconButton">
          <ModalIconClose />
        </div>
      </div>
      <div className="modalBody">
        <div className="modalBody-content">Are you sure you want to leave the page without saving the data?</div>
      </div>
      <div className="modalFooter">
        <Button onClick={() => agreeCloseModal()} name="Yes" background="#D33030" color="white" border="#D33030" />
        <Button name="No" color="#00ADEF" onClick={closePopup} />
      </div>
    </StyledModal>
  );
};

export default ModalUnsavedDataVariant;
