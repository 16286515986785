import * as Yup from 'yup';
import { InfoMenu } from './types';
import { editPoliceById, editRedCrossById, editStandById, getPolice, getRedCross, getStands } from 'src/api/info';

export const infoMenu: InfoMenu[] = [
  {
    title: 'Info Stand',
    getData: getStands,
    editData: editStandById,
  },
  {
    title: 'Police',
    getData: getPolice,
    editData: editPoliceById,
  },
  {
    title: 'Red Cross',
    getData: getRedCross,
    editData: editRedCrossById,
  },
];

export const validateSchema = Yup.object({
  FacebookLink: Yup.string().required('Facebook Link is required'),
  InstagramLink: Yup.string().required('Instagram Link is required'),
  address: Yup.string().required('Aaddress is required'),
  bussinessName: Yup.string().required('Bussiness Name is required'),
  email: Yup.string().required('Email is required'),
  messangerLink: Yup.string().required('Messanger Link is required'),
  phone: Yup.string().required('Phone is required'),
});
