import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { StyledModal } from './styled';
import { getArtistById, editArtistById } from '../../../api/artists';
import { ArtistData } from './types';
import { FileUploader, Button, Input, Checkbox, Textarea } from 'src/components/common';
import { ModalIconClose } from 'src/components/common/icons';
import ModalUnsavedDataVariant from 'src/components/common/ModalUnsavedDataVarianWithoutRedux';

const validateSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  isPrimary: Yup.boolean().required('Main artist is required'),
  artistInfo: Yup.string().required('Artist text is required'),
  imagePath: Yup.string().required('Artist cover is required'),
});

export const EditArtistModal = (props: any): JSX.Element => {
  const { artistId } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenSaveModal, setIsOpenSaveModal] = React.useState(false);
  const [imageKey, setImageKey] = React.useState<string>('');
  const [artist, setArtist] = React.useState<ArtistData>({ name: '', isPrimary: false, artistInfo: '', imagePath: '' });
  const [isDirty, setIsDirty] = React.useState(false);

  if (!props.artistId) {
    return <></>;
  }

  const openModal = () => setIsOpen(true);
  const toggleModal = () => {
    if (isDirty) {
      setIsOpenSaveModal(true);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const getArtist = async () => {
    const artist = await getArtistById(artistId);
    setArtist(artist);
  };

  React.useEffect(() => {
    if (isOpen) {
      getArtist();
    }
  }, [isOpen]);

  const initialValues: ArtistData = {
    name: artist.name,
    isPrimary: artist.isPrimary,
    artistInfo: artist.artistInfo,
    imagePath: artist.imagePath,
  };

  return (
    <>
      <Button onClick={openModal} name="Edit" color="#00ADEF" />
      <ModalUnsavedDataVariant isOpen={isOpenSaveModal} setIsOpen={setIsOpenSaveModal} closeMainModal={setIsOpen} />
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <div className="modalHeader">
          <div title="Edit Artist">Rediger Artist</div>
          <div className="iconButton" onClick={toggleModal}>
            <ModalIconClose />
          </div>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={async values => {
            const formValues = {
              ...values,
              imagePath: `${imageKey || artist.imagePath}`,
            };

            await editArtistById(artistId, formValues);
            await location.reload();
          }}
        >
          {(formik: any) => {
            const { values, errors, touched, isSubmitting, handleSubmit, dirty } = formik;
            React.useEffect(() => {
              setIsDirty(dirty);
            }, [dirty]);

            return (
              <Form className="col" onSubmit={handleSubmit}>
                <div className="modalBody">
                  <div className="twoColumns">
                    <div className="col">
                      <Input name="name" label="Name" placeholder="f.e. Lady Gaga" />
                      <Checkbox name="isPrimary" label="Headliner" checked={values?.isPrimary} />
                    </div>
                    <div className="col">
                      <label className="label">Artist cover</label>
                      <FileUploader imagePath={artist?.imagePath} setImageKey={setImageKey} />
                      {touched.imagePath && errors.imagePath ? <div className="error">{errors.imagePath}</div> : null}
                    </div>
                  </div>
                  <div className="col">
                    <Textarea name="artistInfo" label="Artist text" placeholder="Text" />
                  </div>
                </div>

                <div className="modalFooter">
                  <Button onClick={toggleModal} name="Cancel" color="#00ADEF" type="button" />
                  <Button type="submit" name="Save" background="#00ADEF" color="white" disabled={isSubmitting} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </StyledModal>
    </>
  );
};
