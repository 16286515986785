import { axiosPrivate } from './axios';

export const fetchInfo = async () => axiosPrivate.get('/info');

export const getInfoById = async (id: string) => {
  const { data } = await axiosPrivate.get(`/info/${id}`);
  return data;
};

export const addNewInfo = async (data: any) => {
  await axiosPrivate.post('/info', data);
};

export const editInfoById = async (id: string, data: any) => {
  await axiosPrivate.patch(`/info/${id}`, data);
};

export const deleteInfoById = async (id: string) => {
  await axiosPrivate.delete(`/info/${id}`);
};

// STAND

export const getStands = async () => {
  const { data } = await axiosPrivate.get('/stand');
  return data;
};

// export const getStandById = async (id: string) => {
//   const { data } = await axiosPrivate.get(`/stand/${id}`);
//   return data;
// };

export const editStandById = async (id: string, data: any) => {
  await axiosPrivate.patch(`/stand/${id}`, data);
};

// POLICE

export const getPolice = async () => {
  const { data } = await axiosPrivate.get('/police');
  return data;
};

// export const getPoliceById = async (id: string) => {
//   const { data } = await axiosPrivate.get(`/police/${id}`);
//   return data;
// };

export const editPoliceById = async (id: string, data: any) => {
  await axiosPrivate.patch(`/police/${id}`, data);
};

// RED CROSS

export const getRedCross = async () => {
  const { data } = await axiosPrivate.get('/red-cross');
  return data;
};

// export const getRedCrossById = async (id: string) => {
//   const { data } = await axiosPrivate.get(`/red-cross/${id}`);
//   return data;
// };

export const editRedCrossById = async (id: string, data: any) => {
  await axiosPrivate.patch(`/red-cross/${id}`, data);
};
