import { Navigate, Outlet } from 'react-router-dom';
import { useTypeSelector } from '../hooks/useTypeSelectors';
import { HOME_ROUTE } from './consts';

const PublicRoute = () => {
  const isLogin = useTypeSelector(item => item.user.isLogin);
  return !isLogin ? <Outlet /> : <Navigate to={HOME_ROUTE} />;
};

export default PublicRoute;
