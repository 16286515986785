import React from 'react';
import { useParams } from 'react-router-dom';
import { ModalAddNewGood, ModalEditGood } from '.';
import { deleteGoodById, fetchGoodsById } from '../../api/shop';
import { SearchInput, ModalDelete } from '../common';
import { Page, PageItem } from 'src/styled';
import { GoodData } from './types';

export const Goods = () => {
  const { id } = useParams<string>();

  const [searchInput, setSearchInput] = React.useState('');
  const [goods, setGoods] = React.useState<GoodData[]>([]);

  const getGoods = async (blockId: string, query: string) => {
    const goods = await fetchGoodsById(`${blockId}`, query);
    setGoods(goods);
  };

  React.useEffect(() => {
    getGoods(`${id}`, searchInput);
  }, [deleteGoodById]);

  if (!goods || !id) return null;

  const handleSearch = React.useCallback(() => {
    getGoods(`${id}`, searchInput);
  }, [searchInput]);

  const handleDelete = async (goodId: string) => {
    await deleteGoodById(goodId);
  };

  const filterDataByTitle = (data: any, title: string): any => {
    return data.filter((item: any) => {
      if (item.title.toLowerCase().includes(title.toLowerCase())) {
        return item;
      }
    });
  };

  return (
    <Page>
      <div className="breadCrumbs">
        <a href="/shop">Kjøp</a> {goods[0]?.blockName || ''}
      </div>
      <div className="pageTitle">{goods[0]?.blockName || 'Not Found'}</div>
      <div className="header">
        <SearchInput value={searchInput} onEnter={handleSearch} onInput={(value: string) => setSearchInput(value)} />
        <ModalAddNewGood blockId={`${id}`} />
      </div>
      <div className="listFlex">
        {filterDataByTitle(goods, searchInput).length > 0 ? (
          filterDataByTitle(goods, searchInput)?.map((item: GoodData, index: number) => {
            return (
              <PageItem key={index}>
                <div className="item-header">{item.title}</div>
                <div className="item-body">
                  <ModalEditGood goodId={item.id} />
                  <ModalDelete handleDelete={handleDelete} id={`${item.id}`} />
                </div>
              </PageItem>
            );
          })
        ) : (
          <div className="message-no_items">Ikke funnet...</div>
        )}
      </div>
    </Page>
  );
};
