import { axiosPrivate } from './axios';

export const eventCreate = (
  title: string,
  subtitle: string,
  address: string,
  date: string,
  location: string,
  daytime: string,
  imagePath: string
) => axiosPrivate.post('/events', { title, subtitle, address, date, location, daytime, imagePath });

export const eventImageUpload = (file: any) => axiosPrivate.post('/images/image-upload', file);
export const eventGetData = () => axiosPrivate.get('/events');
export const eventDeleteData = (id: string) => axiosPrivate.delete(`/events/${id}`);
export const eventChangeData = (
  id: string,
  title: string,
  subtitle: string,
  address: string,
  date: string,
  location: string,
  daytime: string,
  imagePath: string
) => axiosPrivate.patch(`/events/${id}`, { title, subtitle, address, date, location, daytime, imagePath });
