import * as React from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';
import NavBar from '../common/NavBar';
import SideBar from '../common/SideBar';

const StyledContainer = styled(Container)`
  font-family: Transat, sans-serif;
  .col-sidebar {
    width: 280px;
    @media (max-width: 920px) {
      display: none;
    }
  }
`;

const Layout = ({ children }: any) => {
  return (
    <StyledContainer fluid>
      <Row>
        {/* sidebar */}
        <Col className="col-sidebar" md={2}>
          <SideBar />
        </Col>
        {/* body */}
        <Col className="p-0">
          <NavBar />
          <div className="content">{children}</div>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Layout;
