import { Navigate, Outlet } from 'react-router-dom';
import { useTypeSelector } from '../hooks/useTypeSelectors';
import { LOGIN_ROUTE } from './consts';

const PrivateRoute = () => {
  const isLogin = useTypeSelector(item => item.user.isLogin);
  return isLogin ? <Outlet /> : <Navigate to={LOGIN_ROUTE} />;
};

export default PrivateRoute;
