const useQuillModule: any = () => {
  const modules: any = (useQuillModule.modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    clipboard: {
      matchVisual: false,
    },
  });

  const formats = (useQuillModule.formats = ['bold', 'italic', 'underline', 'list']);

  return { modules, formats };
};

export default useQuillModule;
