import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  // ARTISTS_ROUTE,
  LOGIN_ROUTE,
  HOME_ROUTE,
  ARTISTS_DETAIL_ROUTE,
  BLOG_ROUTE,
  SHOP_BLOCKS_ROUTE,
  SHOP_GOODS_ROUTE,
  INFO_MAIN_ROUTE,
  CONTACTS_ROUTE,
  EVENT_ARTIST_ROUTE,
  EVENT_MAP_ROUTE,
  EVENT_SHEDULE_ROUTE,
  OFFERS_ROUTE,
  NOTIFICATIONS_CENTER_ROUTE,
} from '../../utils/consts';
import PrivateRoute from '../../utils/PrivateRoute';
// import Artists from '../artists/Artists';
import ArtistDetail from '../artists/ArtistDetail';
import Auth from '../auth/Auth';
import { Event, EventArtists, EventMap, EventSchedule } from '../event';
import Layout from '../layout/Layout';
import { BlogArticles } from '../blog';
import { Blocks, Goods } from '../shop';
import { SpecialOffers } from '../offers';
import { InfoPage } from '../info';
import Contacts from '../contacts/Contacts';
import PublicRoute from '../../utils/PublicRoute';
import NotificationsCenter from '../notifications/NotificationsCenter';

const AppRouter = () => {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route element={<Auth />} path={LOGIN_ROUTE} />
      </Route>
      {/* <Route element={<PrivateRoute />}>
        <Route
          element={
            <Layout>
              <Artists />
            </Layout>
          }
          path={ARTISTS_ROUTE}
        />
      </Route> */}
      <Route element={<PrivateRoute />}>
        <Route
          path={SHOP_BLOCKS_ROUTE}
          element={
            <Layout>
              <Blocks />
            </Layout>
          }
        />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          path={SHOP_GOODS_ROUTE}
          element={
            <Layout>
              <Goods />
            </Layout>
          }
        />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route
          path={INFO_MAIN_ROUTE}
          element={
            <Layout>
              <InfoPage />
            </Layout>
          }
        />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route
          path={NOTIFICATIONS_CENTER_ROUTE}
          element={
            <Layout>
              <NotificationsCenter />
            </Layout>
          }
        />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          path={OFFERS_ROUTE}
          element={
            <Layout>
              <SpecialOffers />
            </Layout>
          }
        />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          path={BLOG_ROUTE}
          element={
            <Layout>
              <BlogArticles />
            </Layout>
          }
        />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          path={CONTACTS_ROUTE}
          element={
            <Layout>
              <Contacts />
            </Layout>
          }
        />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          element={
            <Layout>
              <Event />
            </Layout>
          }
          path={HOME_ROUTE}
        />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          element={
            <Layout>
              <EventSchedule />
            </Layout>
          }
          path={EVENT_SHEDULE_ROUTE}
        />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          element={
            <Layout>
              <EventMap />
            </Layout>
          }
          path={EVENT_MAP_ROUTE}
        />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          element={
            <Layout>
              <EventArtists />
            </Layout>
          }
          path={EVENT_ARTIST_ROUTE}
        />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          element={
            <Layout>
              <ArtistDetail />
            </Layout>
          }
          path={ARTISTS_DETAIL_ROUTE}
        />
      </Route>
    </Routes>
  );
};

export default AppRouter;
