import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { StyledModal } from './styled';
import { addNewArticle } from '../../api/articles';
import { ArticleData } from './types';
import { FileUploader, Button, Input } from '../common';
import FormikControl from 'src/utils/FormikControl';
import { ModalIconClose } from '../common/icons';

const validateSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  subTitle: Yup.string().required('Subtitle is required'),
  description: Yup.string().required('Text is required'),
  cover: Yup.string().required('Article cover is required'),
});

export const AddArticleModal = (): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [imageKey, setImageKey] = React.useState<string>('');

  const initialValues: ArticleData = {
    title: '',
    subTitle: '',
    description: '',
    cover: '',
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Button onClick={toggleModal} name="Add New Article" background="#00ADEF" color="white" />
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <div className="modalHeader">
          <div>Add New Article</div>
          <div className="iconButton" onClick={toggleModal}>
            <ModalIconClose />
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={async values => {
            const formValues = {
              ...values,
              cover: `${imageKey}`,
            };

            await addNewArticle(formValues);
            await toggleModal();
            await location.reload();
          }}
        >
          {(formik: any) => {
            const { errors, touched, isSubmitting, handleSubmit, setFieldValue } = formik;

            React.useEffect(() => {
              setFieldValue('cover', imageKey);
            }, [imageKey]);

            return (
              <Form className="col" onSubmit={handleSubmit}>
                <div className="modalBody">
                  <div className="twoColumns">
                    <div className="col">
                      <Input name="title" label="Title" placeholder="f.e. Lady Gaga" />
                      <Input name="subTitle" label="Subtitle" placeholder="f.e. Lady Gaga" />
                    </div>
                    <div className="col">
                      <label className="label">Article cover</label>
                      <FileUploader name="cover" imagePath={imageKey} setImageKey={setImageKey} />
                      {touched.cover && errors.cover ? <div className="error">{errors.cover}</div> : null}
                    </div>
                  </div>
                  <div className="col">
                    <FormikControl control="editor" name="description" label="Article text" />
                    {touched.description && errors.description ? (
                      <div className="error">{errors.description}</div>
                    ) : null}
                  </div>
                </div>

                <div className="modalFooter">
                  <Button onClick={toggleModal} name="Cancel" color="#00ADEF" />
                  <Button type="submit" name="Save" background="#00ADEF" disabled={isSubmitting} color="white" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </StyledModal>
    </div>
  );
};
