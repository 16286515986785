export const ModalIconClose = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 7L16 16M16 16L7 25M16 16L25 25M16 16L25 7"
      stroke="#929292"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
