export enum UserActionType {
  LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS',
  LOGIN_USER_ERROR = 'LOGIN_USER_ERROR',
  LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS',
}

export interface LoginUserSuccess {
  type: UserActionType.LOGIN_USER_SUCCESS;
  payload: string;
}

interface LoginUserError {
  type: UserActionType.LOGIN_USER_ERROR;
  payload: string;
}

export interface LogoutUserSuccess {
  type: UserActionType.LOGOUT_USER_SUCCESS;
  payload: string;
}

export type UserActions = LoginUserSuccess | LoginUserError | LogoutUserSuccess;

export interface UserState {
  isLogin: boolean | null;
  token: string;
  error: string;
}
