import Modal from 'styled-react-modal';

export const StyledModal = Modal.styled`
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-family: Transat;
  border-radius: 16px;
  
  .modalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 700;  
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid #ebebeb;
  }
  .modalBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    padding-bottom: 14px;
    padding-top: 14px;
    .error-line {
      color: red;
      padding-left: 10px;
      margin-top: 10px;
    }
    .modal__title-time {
      margin-bottom: 10px;
    }
    .modal__title-artist {
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .modal__title-scene {
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .unactive {
      button#dropdown-item-button {
        box-shadow: none;
        background: white;
        color: #929292;
        border-color: #EBEBEB;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        border-radius: 8px;
      }
    }
    .active {
      button#dropdown-item-button {
        box-shadow: none;
        background: white;
        color: black;
        border-color: #EBEBEB;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        border-radius: 8px;
      }
    }
    .dropdown-menu {
      width: 100%;
    }
    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
      opacity: 0.35;
      background: #000000;
    }
    .modalBody-text_info {
      border: 2px dashed #00ADEF;
      color: #00ADEF;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 14px;
      margin-bottom: 16px;
    }
    .modalBody-title {
       margin-top: 16px;
       margin-bottom: 8px;
       font-size: 16px;
       font-weight: 400;
       line-height: 24px;
       letter-spacing: 0px;
       text-align: left;
    }
    .modalBody-first_title {
      margin-bottom: 8px;
    }
    .modalBody-time_based {
      margin-top: 16px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .modalBody-time_based-update {
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    .modalBody-time_notifications {
       margin-top: 16px;
       font-size: 16px;
       font-weight: 400;
       line-height: 24px;
       letter-spacing: 0px;
       display: flex;
       flex-direction: column;
       gap: 8px;
    }
  }

  .modalFooter {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px;
    border-top: 1px solid #ebebeb;
    .modalFooter-error {
      position: absolute;
      color: red;
      top: 5px;
      font-size: 13px;
    }
    .button-cancel {
      color: #00ADEF;
      cursor: pointer;
      width: 72px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      box-sizing: border-box;
    }
  }
  
  .iconButton {
    cursor: pointer;
  }
`;
