import React from 'react';
import { useNavigate } from 'react-router-dom';
interface navigateButtonTypes {
  navigateTo: string;
  buttonData: string;
}

const CheverlonLeft = () => (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.75 9.5L5.25 5L0.75 0.5" stroke="#181C32" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const NavigateButton = ({ navigateTo, buttonData }: navigateButtonTypes) => {
  const navigate = useNavigate();
  const navigateHandler = () => navigate(navigateTo);

  return (
    <div className="NavigateButton" onClick={navigateHandler}>
      {buttonData}
      <div className="NavigateButton-image">
        <CheverlonLeft />
      </div>
    </div>
  );
};

export default NavigateButton;
