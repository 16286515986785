import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { profileData } from '../../api/users';
import LoaderBootstrap from './LoaderBootstrap';
import { USER_AUTH_REFRESH_TOKEN, USER_AUTH_COMMON_TOKEN } from '../../utils/consts';

const NavBar = () => {
  const [user, setUser] = useState({ name: '', email: '', isLoading: true });

  useEffect(() => {
    profileData()
      .then(item => {
        setUser({ ...item.data, isLoading: false });
      })
      .catch(err => {
        console.log('ERR', err);
      });
  }, []);

  const logoutUser = () => {
    localStorage.removeItem(USER_AUTH_REFRESH_TOKEN);
    localStorage.removeItem(USER_AUTH_COMMON_TOKEN);

    window.location.reload();
  };

  return (
    <Navbar expand="lg" className="navbar">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {user.isLoading ? (
          <LoaderBootstrap />
        ) : (
          <Nav className="ml-auto">
            <div className="navbar__avatar">
              {user.name.slice(0, 1)}
              <div className="navbar__avatar__status navbar__avatar__status"></div>
            </div>
            <NavDropdown title={user.name}>
              <NavDropdown.Item onClick={logoutUser}>Sign out</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        )}
      </Container>
    </Navbar>
  );
};

export default NavBar;
