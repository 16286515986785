import axios from 'axios';
import { BASE_API_URL, USER_AUTH_COMMON_TOKEN, USER_AUTH_REFRESH_TOKEN } from '../utils/consts';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';

export const axiosPublic = axios.create({
  baseURL: BASE_API_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_API_URL,
});

axiosPrivate.interceptors.request.use(async function (config: any) {
  const accessToken = JSON.parse(localStorage.getItem(USER_AUTH_COMMON_TOKEN) || '');
  const refreshToken = JSON.parse(localStorage.getItem(USER_AUTH_REFRESH_TOKEN) || '');
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
  const user: any = jwt_decode(accessToken);
  const isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 1;
  if (!isExpired) {
    return config;
  }
  await axiosPublic
    .post(
      '/auth/refresh',
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    )
    .then(item => {
      if (item.status === 200) {
        const refreshToken = item.data.refresh_token;
        const accessToken = item.data.access_token;
        localStorage.setItem(USER_AUTH_REFRESH_TOKEN, JSON.stringify(refreshToken));
        localStorage.setItem(USER_AUTH_COMMON_TOKEN, JSON.stringify(accessToken));
        axiosPrivate.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        window.location.reload();
      }
    })
    .catch(err => {
      localStorage.removeItem(USER_AUTH_REFRESH_TOKEN);
      localStorage.removeItem(USER_AUTH_COMMON_TOKEN);
      window.location.reload();
      console.log(err);
    });
  return config;
});

axiosPrivate.interceptors.response.use(
  response => response,
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      axiosPublic
        .post(
          '/auth/refresh',
          {},
          { headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem(USER_AUTH_REFRESH_TOKEN) || '')}` } }
        )
        .then(item => {
          if (item.status === 200) {
            const refreshToken = item.data.refresh_token;
            const accessToken = item.data.access_token;
            localStorage.setItem(USER_AUTH_REFRESH_TOKEN, JSON.stringify(refreshToken));
            localStorage.setItem(USER_AUTH_COMMON_TOKEN, JSON.stringify(accessToken));
            axiosPrivate.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            window.location.reload();
          } else {
            localStorage.removeItem(USER_AUTH_REFRESH_TOKEN);
            localStorage.removeItem(USER_AUTH_COMMON_TOKEN);
            window.location.reload();
          }
        })
        .catch(err => {
          localStorage.removeItem(USER_AUTH_REFRESH_TOKEN);
          localStorage.removeItem(USER_AUTH_COMMON_TOKEN);
          window.location.reload();
          console.log(err);
        });
      originalRequest._retry = true;
      return axiosPrivate(originalRequest);
    }
    return Promise.reject(error);
  }
);
