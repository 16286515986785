import { axiosPrivate } from './axios';

export interface notifications {
  title: string;
  subtitle: string;
  link: string;
  timeBase?: boolean;
  dateTime?: any;
}

export interface pushNotifications {
  id: string;
  title?: string;
  subtitle?: string;
  link?: string;
  dateTime?: any;
  sent?: boolean;
  timeBase?: boolean;
}

export const getNotifications = () => axiosPrivate.get('/notifications');

export const getOneNotifications = (id: string) => axiosPrivate.get(`/notifications/${id}`);

export const createNotifications = ({ title, subtitle, link, timeBase, dateTime }: notifications) =>
  axiosPrivate.post('/notifications', { title, subtitle, link, timeBase, dateTime });

export const removeNotifications = (id: string) => axiosPrivate.delete(`/notifications/${id}`);

export const updateNotifications = ({ id, title, subtitle, link, dateTime, sent, timeBase }: pushNotifications) =>
  axiosPrivate.patch(`/notifications/${id}`, { title, subtitle, link, dateTime, sent, timeBase });
