import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { getOneSchedule, updateSchedule } from 'src/api/event-schedule';
import { Button } from 'src/components/common/Button';
import DateTimesPicker from 'src/components/common/DatePicker';
import { ModalIconClose } from 'src/components/common/icons';
import ModalUnsavedDataVariant from 'src/components/common/ModalUnsavedDataVarianWithoutRedux';
import UniqueInput from 'src/components/common/UniqueInput';
import { StyledModal } from './styled';
interface modalAddInterface {
  show: boolean;
  closeModal: () => any;
  artists: any;
  updateIdItem: string;
  updateData?: (item: any) => void;
}

const ModalUpdateNewItem = ({ show, closeModal, artists, updateIdItem }: modalAddInterface) => {
  const [currenArtisitId, setCurrentArtistId] = useState('');
  const [scene, setScene] = useState('');
  const [artist, setArtist] = useState<any>('');
  const [time, setTime] = useState<any>(new Date());
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [isValid, setIsValid] = useState<null | boolean>(null);
  const firsUpdate = React.useRef(false);
  const [currentData, setCurrentData] = useState<any>({});
  const [isDirty, setIsDirty] = useState(false);
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false);

  const closeModalVariantSecond = (item: boolean) => {
    closeModal();
    window.location.reload();
  };
  const closeModalWithDirty = () => {
    if (isDirty) {
      setIsOpenSaveModal(true);
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    if (!show) {
      firsUpdate.current = false;
    }
  }, [show]);

  useEffect(() => {
    if (firsUpdate.current && show) {
      if (
        scene !== currentData.stage ||
        artist !== currentData.artist.name ||
        time.getFullYear() !== new Date(currentData.time).getFullYear() ||
        time.getMonth() !== new Date(currentData.time).getMonth() ||
        time.getDay() !== new Date(currentData.time).getDay() ||
        time.getHours() !== new Date(currentData.time).getHours() ||
        time.getMinutes() !== new Date(currentData.time).getMinutes()
      ) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }
  }, [scene, artist, time, hours, minutes, show]);

  const updateScheduleData = () => {
    if (
      setCurrentArtistId?.length !== 0 &&
      time?.length !== 0 &&
      scene?.length !== 0 &&
      minutes?.length !== 0 &&
      hours?.length !== 0
    ) {
      updateSchedule({ id: updateIdItem, time, artistId: currenArtisitId, stage: scene })
        .then(() => {
          window.location.reload();
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    if (updateIdItem.length > 0) {
      getOneSchedule(updateIdItem)
        .then(item => {
          const { artist, stage, time } = item.data;
          const gotTime = new Date(time);
          setHours(gotTime.getHours().toString());
          setMinutes(gotTime.getMinutes().toString());
          setTime(gotTime);
          setScene(stage);
          setArtist(artist.name);
          const currentArtistId = artists.find((item: any) => item.name === artist.name);
          setCurrentData({ artist, stage, time, artistId: currentArtistId });
          setCurrentArtistId(currentArtistId.id);
          firsUpdate.current = true;
        })
        .catch(err => console.log(err));
    }
  }, [updateIdItem]);

  return (
    <>
      <ModalUnsavedDataVariant
        isOpen={isOpenSaveModal}
        setIsOpen={setIsOpenSaveModal}
        closeMainModal={closeModalVariantSecond}
      />
      <StyledModal isOpen={show}>
        <div className="modalHeader">
          <div>Update Schedule Entry</div>
          <div onClick={closeModalWithDirty} className="iconButton">
            <ModalIconClose />
          </div>
        </div>
        <div className="modalBody">
          <div className="modal__title-time">Time</div>
          <DateTimesPicker
            hours={hours}
            setHours={setHours}
            minutes={minutes}
            setMinutes={setMinutes}
            setDate={setTime}
            initialDate={time}
          />
          <div className="modal__title-artist">Artist</div>
          <DropdownButton
            className={artist.length > 0 ? 'active' : 'unactive'}
            id="dropdown-item-button"
            title={artist ? artist : ''}
          >
            {artists.map((item: any) => (
              <Dropdown.Item
                onClick={(e: any) => {
                  setCurrentArtistId(e.target.id);
                  setArtist(e.target.name);
                }}
                key={item.id}
                as="button"
                name={item.name}
                id={item.id}
              >
                {item.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <div className="modal__title-scene">Scene</div>
          <UniqueInput item={scene} setItem={setScene} style={{ width: '100%' }} placeStr="f.e. Lorem" type={0} />
          {isValid === false && <div className="error-line">All fields must be filled</div>}
        </div>
        <div className="modalFooter">
          <div className="button-cancel" onClick={closeModalWithDirty}>
            Cancel
          </div>
          <Button onClick={updateScheduleData} name="Save" />
        </div>
      </StyledModal>
    </>
  );
};

export default ModalUpdateNewItem;
