import React from 'react';

interface uniqueInput {
  placeStr: string;
  type?: number;
  setItem?: any;
  item?: any;
  style?: any;
  inpType?: string;
}

const UniqueInput = ({ placeStr, type, item, setItem, style, inpType }: uniqueInput) => {
  if (type === 2) {
    return (
      <div className="UniqueInput" style={{ ...style }}>
        <input
          value={item}
          onChange={e => setItem(e.target.value)}
          className="UniqueInput-input_with-logo"
          placeholder={placeStr}
          type={inpType}
        />
        <div className="UniqueInput-image_location" />
      </div>
    );
  } else if (type === 1) {
    return (
      <div className="UniqueInput" style={{ ...style }}>
        <input
          value={item}
          onChange={e => setItem(e.target.value)}
          className="UniqueInput-input_with-logo"
          placeholder={placeStr}
          type={inpType}
        />
        <div className="UniqueInput-image_calendar" />
      </div>
    );
  } else {
    return (
      <div className="UniqueInput" style={{ ...style }}>
        <input
          value={item}
          onChange={e => setItem(e.target.value)}
          className="UniqueInput-input"
          placeholder={placeStr}
          type={inpType}
        />
      </div>
    );
  }
};

export default UniqueInput;
