import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

interface InputProps {
  name: string;
  label?: string;
  value?: string;
  background?: string;
  onClick?: () => void;
  onChange?: () => void;
  onBlur?: () => void;
  type?: 'button' | 'submit' | 'reset';
}

const StyledCheckbox = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  .checkboxWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .label {
    font-size: 16px;
    font-weight: 400;
  }

  .checkbox {
    border: 1px solid #ebebeb;
    outline: none;
    width: 18px;
    height: 18px;
  }
`;

export const Checkbox = ({ label, ...props }: any): JSX.Element => {
  const [field, meta] = useField(props);

  return (
    <StyledCheckbox {...props}>
      <div className="checkboxWrapper">
        <input type="checkbox" className="checkbox" checked={field.value} {...field} {...props} />
        {label && (
          <label className="label" htmlFor={props.id || props.name}>
            {label}
          </label>
        )}
      </div>
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </StyledCheckbox>
  );
};
