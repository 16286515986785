import React, { useEffect, useState } from 'react';
import { getOneNotifications, updateNotifications } from 'src/api/notifications';
import DateTimesPicker from '../common/DatePicker';
import SwitchToggle from '../common/SwitchToggle';
import UniqueInput from '../common/UniqueInput';
import { ModalIconClose } from './icons';
import { StyledModal } from './styled';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import ModalUnsavedDataVariant from '../common/ModalUnsavedDataVarianWithoutRedux';

interface modalAddNotifications {
  isOpen: boolean;
  setIsOpen: (item: any) => void;
  id: string;
  updateData: any;
  partOfTitle: string;
  buttonContent: string;
  isArchive: boolean;
}

const ModalUpdateNotifications = ({
  isOpen,
  setIsOpen,
  id,
  updateData,
  partOfTitle,
  buttonContent,
  isArchive,
}: modalAddNotifications) => {
  const [curentData, setCurrentData] = useState<any>({});
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false);
  const [initialDate, setInitialDate] = useState(new Date());
  const [minutes, setMinutes] = useState('');
  const [hours, setHours] = useState('');
  const [isTimeBase, setIsTimeBase] = useState(false);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [link, setLink] = useState('');
  const [isCorrect, setIsCorrect] = useState<boolean | undefined>(undefined);
  const [isDirty, setIsDirty] = useState(false);
  const firstRender = React.useRef(false);

  const togglePopup = () => {
    if (isDirty) {
      setIsOpenSaveModal(true);
    } else {
      setIsOpen((prev: boolean) => !prev);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getOneNotifications(id)
        .then(item => {
          const { dateTime, link, subtitle, timeBase, title } = item.data;
          const minutes = new Date(dateTime).getMinutes();
          const hours = new Date(dateTime).getHours();
          setMinutes(minutes.toString());
          setHours(hours.toString());
          setTitle(title);
          setSubtitle(subtitle);
          setLink(link);
          setIsTimeBase(timeBase);
          setInitialDate(new Date(dateTime));
          setCurrentData({ id, title, subtitle, dateTime, link, timeBase });
          firstRender.current = true;
        })
        .catch(err => console.log(err));
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && firstRender.current) {
      if (
        title !== curentData?.title ||
        subtitle !== curentData?.subtitle ||
        link !== curentData.link ||
        isTimeBase !== curentData.timeBase ||
        initialDate.getFullYear() !== new Date(curentData.dateTime).getFullYear() ||
        initialDate.getMonth() !== new Date(curentData.dateTime).getMonth() ||
        initialDate.getDay() !== new Date(curentData.dateTime).getDay() ||
        initialDate.getHours() !== new Date(curentData.dateTime).getHours() ||
        initialDate.getMinutes() !== new Date(curentData.dateTime).getMinutes()
      ) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }
  }, [title, subtitle, link, initialDate, hours, minutes, isTimeBase]);
  const updateNotificationsData = () => {
    if (title.length !== 0 && subtitle.length !== 0 && link.length !== 0) {
      updateNotifications({ id, title, subtitle, dateTime: initialDate, link, timeBase: isTimeBase })
        .then(() => {
          setIsOpen(false);
          updateData((prev: any) => [...prev, 1]);
        })
        .catch(err => console.log(err));
    } else {
      setIsCorrect(false);
    }
  };

  return (
    <>
      <ModalUnsavedDataVariant isOpen={isOpenSaveModal} setIsOpen={setIsOpenSaveModal} closeMainModal={setIsOpen} />
      <StyledModal isOpen={isOpen}>
        <div className="modalHeader">
          <div>{partOfTitle} Notification Edit</div>
          <div className="iconButton" onClick={togglePopup}>
            <ModalIconClose />
          </div>
        </div>
        <div className="modalBody">
          <SimpleBar autoHide={false} style={{ maxHeight: '375px', overflowX: 'hidden' }}>
            <div className="modalBody-container">
              <div className="modalBody-time_based-update">
                Time-based
                <SwitchToggle onChange={setIsTimeBase} isChecked={isTimeBase} />
              </div>
              {isArchive && (
                <div className="modalBody-text_info">
                  {isTimeBase
                    ? 'This Notification is in Archive because it has been already sent. If you want to re-activate it - fill in the time of the notification field and click the Re-activate button.'
                    : 'This Notification is in Archive because it has been already sent. If you want to re-activate it just click the Re-activate button'}
                </div>
              )}
              <div className="modalBody-first_title">Title</div>
              <UniqueInput item={title} setItem={setTitle} style={{ width: '100%' }} placeStr="f.e. Offer 1" type={0} />
              <div className="modalBody-title">Subtitle</div>
              <UniqueInput
                item={subtitle}
                setItem={setSubtitle}
                style={{ width: '100%' }}
                placeStr="f.e. Offer 1"
                type={0}
              />
              <div className="modalBody-title">Link</div>
              <UniqueInput item={link} setItem={setLink} style={{ width: '100%' }} placeStr="f.e. Offer 1" type={0} />
              {isTimeBase && (
                <div className="modalBody-time_notifications">
                  Time Of The Notification
                  <DateTimesPicker
                    hours={hours}
                    setHours={setHours}
                    minutes={minutes}
                    setMinutes={setMinutes}
                    initialDate={initialDate}
                    setDate={setInitialDate}
                  />
                </div>
              )}
            </div>
          </SimpleBar>
        </div>
        <div className="modalFooter">
          {isCorrect === false && <div className="modalFooter-error">All fields must be filled...</div>}
          <div onClick={togglePopup} className="modalFooter-button__cancel">
            Cancel
          </div>
          <div onClick={updateNotificationsData} className="modalFooter-button__save">
            {buttonContent}
          </div>
        </div>
      </StyledModal>
    </>
  );
};

export default ModalUpdateNotifications;
