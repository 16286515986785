import React from 'react';
import { Button } from '.';
import Modal from 'styled-react-modal';
import { ModalIconClose } from './icons';

export const StyledModal = Modal.styled`
  font-family: Transat, sans-serif;
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 16px;
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 700;  
    width: 100%;
    padding: 24px;
    border-bottom: 1px solid #ebebeb;
  }

  .modalBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
  }

  .modalFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 24px;
    border-top: 1px solid #ebebeb;
  }

  .col {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .iconButton {
    cursor: pointer;
    font-weight: 400;
  }

`;

interface ModalProps {
  id: string;
  handleDelete: (id: string) => void;
}

export const ModalDelete = (props: ModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);

  if (!props.id) {
    return <></>;
  }

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteButton = async (id: string) => {
    await props.handleDelete(id);
    await setIsOpen(!isOpen);
    await location.reload();
  };

  return (
    <>
      <Button onClick={toggleModal} name="Delete" color="#D33030" border="none" />
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <div className="modalHeader">
          <div>Delete</div>
          <div className="iconButton" onClick={toggleModal}>
            <ModalIconClose />
          </div>
        </div>

        <div className="modalBody">
          <div className="col" style={{ alignItems: 'center' }}>
            Are You Sure You Want To Delete?
          </div>
        </div>

        <div className="modalFooter">
          <Button onClick={toggleModal} name="Cancel" color="#00ADEF" />
          <Button
            onClick={() => handleDeleteButton(props.id)}
            name="Delete"
            background="#D33030"
            color="white"
            border="#D33030"
          />
        </div>
      </StyledModal>
    </>
  );
};
