import React from 'react';
import DatePicker from 'react-date-picker';
import styled from 'styled-components';

interface datePicker {
  initialDate: any;
  setDate: (item: any) => void;
  hours?: string;
  setHours?: any;
  minutes?: string;
  setMinutes?: any;
}

const DateAndTimePicker = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  .react-date-picker {
    width: 100%;
    height: 50px;
    .react-date-picker__wrapper {
      padding: 10px;
      padding-left: 10px;
      border-radius: 10px;
      border: 1px solid #ebebeb;
      .react-date-picker__clear-button {
        display: none;
      }
    }
  }
  input[type='time'] {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #ebebeb;
    padding: 10px;
    padding-left: 20px;
    outline: none;
  }
`;

const DateTimesPicker = ({ initialDate, setDate, hours, minutes, setHours, setMinutes }: datePicker) => {
  const checkDateLength = (check: any) => {
    return check?.length === 1 ? 0 + check : check;
  };

  return (
    <DateAndTimePicker>
      <DatePicker locale={'en'} onChange={setDate} value={initialDate} format={'dd/MM/y'} />
      <input
        value={`${checkDateLength(hours)}:${checkDateLength(minutes)}`}
        onChange={(e: any) => {
          setHours(e.target.value?.split(':')[0]);
          setMinutes(e.target.value?.split(':')[1]);
          if (e.target?.value?.length === 5) {
            initialDate.setHours(e.target.value?.split(':')[0]);
            initialDate.setMinutes(e.target.value?.split(':')[1]);
          }
        }}
        type={'time'}
      />
    </DateAndTimePicker>
  );
};

export default DateTimesPicker;
