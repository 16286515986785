import React from 'react';
import styled from 'styled-components';

interface ButtonProps {
  color?: string;
  name?: string;
  title?: string;
  border?: string;
  background?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  color: ${({ color }) => color || '#00ADEF'};
  background: ${props => props.background || '#fff'};
  border: ${({ border, color }) => (border === 'none' ? 'none' : `1px solid ${color || '#00ADEF'}`)};
  font-weight: 700;
  border-radius: 10px;

  :hover,
  :active {
    color: ${props => props.background || '#fff'};
    background: ${props => props.color || '#00ADEF'};
    border: ${({ border, background }) => (border === 'none' ? 'none' : `1px solid ${background || '#00ADEF'}`)};
  }
`;

export const Button = React.memo((props: ButtonProps): JSX.Element => {
  return <StyledButton {...props}>{props.name}</StyledButton>;
});
