import React from 'react';
import { AddArticleModal, EditArticleModal } from './';
import { deleteArticleById, fetchArticles } from '../../api/articles';
import { SearchInput, ModalDelete } from '../common';
import { Page, PageItem } from 'src/styled';
import { ArticleData } from './types';

export const BlogArticles = () => {
  const [searchInput, setSearchInput] = React.useState('');
  const [articles, setArticles] = React.useState<ArticleData[]>([]);

  const getArticles = async () => {
    const articles = await fetchArticles(searchInput);
    setArticles(articles);
  };

  const filterDataByTitle = (data: any, title: string): any => {
    return data.filter((item: any) => {
      if (item.title.toLowerCase().includes(title.toLowerCase())) {
        return item;
      }
    });
  };

  React.useEffect(() => {
    getArticles();
  }, [deleteArticleById]);

  if (!articles) return null;

  const handleSearch = React.useCallback(() => {
    getArticles();
  }, [searchInput]);

  const handleDelete = async (id: string) => {
    await deleteArticleById(id);
  };

  return (
    <Page>
      <div className="pageTitle" title="Blog">
        Nyheter
      </div>
      <div className="header">
        <SearchInput value={searchInput} onEnter={handleSearch} onInput={(value: string) => setSearchInput(value)} />
        <AddArticleModal />
      </div>
      <div className="listFlex">
        {filterDataByTitle(articles, searchInput).length > 0 ? (
          filterDataByTitle(articles, searchInput).map((item: ArticleData, index: number) => {
            return (
              <PageItem key={index}>
                <div className="item-header">{item.title}</div>
                <div className="item-body">
                  <EditArticleModal articleId={item.id} />
                  <ModalDelete handleDelete={handleDelete} id={`${item.id}`} />
                </div>
              </PageItem>
            );
          })
        ) : (
          <div className="message-no_items">Nyhetene er tomme...</div>
        )}
      </div>
    </Page>
  );
};
