import React, { useEffect, useState } from 'react';
import { getNotifications } from 'src/api/notifications';
import { Button, SearchInput } from '../common';
import LoaderBootstrap from '../common/LoaderBootstrap';
import ModalAddNotifications from './ModalAddNotifications';
import { NotificationsItem } from './NotificationsItem';

const NotificationsCenter = () => {
  const [isOpenAddNotificationsModal, setIsOpenAddNotificationsModal] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [data, setData] = useState<any>(null);
  const [searchWord, setSearchWord] = useState('');

  const filterDataByTitleTimeBase = (data: any, title: string): any => {
    return data.filter((item: any) => {
      if (!item.sent && item.timeBase) {
        if (item.title.toLowerCase().includes(title.toLowerCase())) {
          return item;
        }
      }
    });
  };

  const filterDataByTitlePushBase = (data: any, title: string): any => {
    return data.filter((item: any) => {
      if (!item.sent && !item.timeBase) {
        if (item.title.toLowerCase().includes(title.toLowerCase())) {
          return item;
        }
      }
    });
  };

  const filterDataByTitleSent = (data: any, title: string): any => {
    return data.filter((item: any) => {
      if (item.sent) {
        if (item.title.toLowerCase().includes(title.toLowerCase())) {
          return item;
        }
      }
    });
  };

  const updateNotifications = () => {
    getNotifications()
      .then(item => {
        setData(item.data);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => updateNotifications(), []);
  useEffect(() => updateNotifications(), [updateData]);
  return (
    <div className="NotificationsCenter">
      <ModalAddNotifications
        updateData={setUpdateData}
        isOpen={isOpenAddNotificationsModal}
        setIsOpen={setIsOpenAddNotificationsModal}
      />
      <div className="NotificationsCenter-container">
        <div className="NotificationsCenter-main">
          <div className="NotificationsCenter-main__title">Notifications center</div>
          <div className="NotificationsCenter-main__searchbar">
            <SearchInput value={searchWord} onInput={setSearchWord} />
            <Button
              onClick={() => setIsOpenAddNotificationsModal(true)}
              name="Add New Notification"
              background="#00ADEF"
              color="white"
            />
          </div>
          <div className="NotificationsCenter-main__notifications">
            <div className="NotificationsCenter-main__notifications-time_based">
              <div className="time_based__title">Time-based notification</div>
              <div className="time_based__container">
                {data === null ? (
                  <div className="center-loader">
                    <LoaderBootstrap />
                  </div>
                ) : filterDataByTitleTimeBase(data, searchWord).length > 0 ? (
                  filterDataByTitleTimeBase(data, searchWord).map((item: any) => {
                    if (!item.sent && item.timeBase) {
                      return (
                        <NotificationsItem
                          updateData={setUpdateData}
                          id={item.id}
                          key={item.id}
                          title={item.title}
                          text={item.dateTime}
                          buttonText={'Push Now'}
                          typeEdit={'Time-based'}
                          modalButtonUpdateContent={'Save'}
                          isArchive={false}
                        />
                      );
                    }
                  })
                ) : (
                  <div className="NotificationsCenter-main__empty-block">Time-based notification is empty...</div>
                )}
              </div>
            </div>
            <div className="NotificationsCenter-main__notifications-push_based">
              <div className="push_based__title">Push-based notification</div>
              <div className="push_based__container">
                {data === null ? (
                  <div className="center-loader">
                    <LoaderBootstrap />
                  </div>
                ) : filterDataByTitlePushBase(data, searchWord).length > 0 ? (
                  filterDataByTitlePushBase(data, searchWord).map((item: any) => {
                    if (!item.sent && !item.timeBase) {
                      return (
                        <NotificationsItem
                          updateData={setUpdateData}
                          id={item.id}
                          key={item.id}
                          title={item.title}
                          buttonText={'Push'}
                          typeEdit={'Push-based'}
                          modalButtonUpdateContent={'Save'}
                          isArchive={false}
                        />
                      );
                    }
                  })
                ) : (
                  <div className="NotificationsCenter-main__empty-block">Push-based notification is empty...</div>
                )}
              </div>
            </div>
            <div className="NotificationsCenter-main__notifications-sent_notifications">
              <div className="sent_notifications__title">Sent Notifications (Archive)</div>
              <div className="sent_notifications__container">
                {data === null ? (
                  <div className="center-loader">
                    <LoaderBootstrap />
                  </div>
                ) : filterDataByTitleSent(data, searchWord).length > 0 ? (
                  filterDataByTitleSent(data, searchWord).map((item: any) => {
                    if (item.sent) {
                      return (
                        <NotificationsItem
                          updateData={setUpdateData}
                          id={item.id}
                          key={item.id}
                          title={item.title}
                          typeEdit={'Archive'}
                          modalButtonUpdateContent={'Re-Activate'}
                          isArchive={true}
                        />
                      );
                    }
                  })
                ) : (
                  <div className="NotificationsCenter-main__empty-block">Sent Notifications (Archive) is empty...</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsCenter;
