import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import UniqueInput from '../common/UniqueInput';
import { useDropzone } from 'react-dropzone';
import NavigateButton from '../common/NavigateButton';
import { EVENT_SHEDULE_ROUTE, EVENT_MAP_ROUTE, EVENT_ARTIST_ROUTE } from '../../utils/consts';
import { eventImageUpload, eventCreate, eventGetData, eventChangeData } from 'src/api/event';
import { useActions } from 'src/hooks/useActions';
import { useTypeSelector } from 'src/hooks/useTypeSelectors';
import ModalUnsavedData from '../common/ModalUnsavedData';

interface eventMessage {
  isHide: boolean;
  message: string;
  isSuccess: boolean | null;
}
export const Event = () => {
  const [message, setMessage] = useState<eventMessage>({
    isHide: true,
    message: '',
    isSuccess: null,
  });
  const [files, setFiles] = useState<any>(null);
  const [title, setTitle] = useState('');
  const [imagePath, setImagePath] = useState('');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');
  const [subtitle, setSubtilte] = useState('');
  const [address, setAddress] = useState('');
  const [daytime, setDaytime] = useState('');
  const [isCreatedData, setIsCreatedData] = useState(false);
  const [eventId, setEventId] = useState('');
  const firstUpdate = useRef<any>(false);
  const actions = useActions();
  const { isOpenModalEvent } = useTypeSelector(item => item.modal);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      actions.changedEventData(true);
    }
  }, [title, address, location, subtitle, date, daytime, files]);

  useEffect(() => {
    eventGetData()
      .then(item => {
        if (item.data.length > 0) {
          const { id, address, date, daytime, imagePath, location, subtitle, title } = item.data[0];
          setIsCreatedData(true);
          setEventId(id);
          setAddress(address);
          setDate(date);
          setDaytime(daytime);
          setImagePath(imagePath);
          setLocation(location);
          setSubtilte(subtitle);
          setTitle(title);
          firstUpdate.current = true;
        } else {
          setIsCreatedData(false);
        }
        return item;
      })
      .catch(err => {
        setMessage({ isHide: false, message: 'Error with upload data', isSuccess: false });
        console.log(err);
      });
  }, []);

  const onSendForm = () => {
    if (
      title.length > 0 &&
      subtitle.length > 0 &&
      address.length > 0 &&
      date.length > 0 &&
      location.length > 0 &&
      imagePath.length > 0 &&
      daytime.length > 0
    ) {
      if (isCreatedData) {
        eventChangeData(eventId, title, subtitle, address, date, location, daytime, imagePath)
          .then(() => {
            setMessage({ isHide: false, message: 'Successfully changed data', isSuccess: true });
            setTimeout(() => {
              setMessage({} as eventMessage);
            }, 5000);
          })
          .catch(err => {
            setMessage({ isHide: false, message: 'Error creating event, please reload page', isSuccess: false });
            setTimeout(() => {
              setMessage({} as eventMessage);
            }, 5000);
            console.log(err);
          });
      } else {
        eventCreate(title, subtitle, address, date, location, imagePath, daytime)
          .then(item => {
            if (isCreatedData) {
              setMessage({ isHide: false, message: 'Successfully changed data', isSuccess: true });
            } else {
              setMessage({ isHide: false, message: 'Success created event', isSuccess: true });
            }
            setTimeout(() => {
              setMessage({} as eventMessage);
            }, 5000);
            console.log(item);
          })
          .catch(err => {
            setMessage({ isHide: false, message: 'Error creating event, please reload page', isSuccess: false });
            setTimeout(() => {
              setMessage({} as eventMessage);
            }, 5000);
            console.log(err);
          });
      }
    } else {
      setMessage({ isHide: false, message: 'All fields are required', isSuccess: false });
      setTimeout(() => {
        setMessage({} as eventMessage);
      }, 5000);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFile => {
      setFiles(
        acceptedFile.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const onSave = async (files: any) => {
    const file = files[0];
    if (file.webkitRelativePath !== undefined) {
      const formData = new FormData();
      formData.append('file', file);
      await eventImageUpload(formData)
        .then(item => {
          setImagePath(item.data.key);
          return item;
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (files) {
      onSave(files);
    }
  }, [files]);
  return (
    <div className="event-page">
      <div className="event-page__container">
        <ModalUnsavedData isOpen={isOpenModalEvent} />
        <div className="event-page__breadcrumb-container">
          <div className="event-page__breadcrumb-item_1">Om LS</div>
        </div>
        <div className="event-page__main">
          <div className="event-page__main-title" title="Event">
            Om LS
          </div>
          <div className="event-page__main-block-upload">
            <div className="event-page__main-block-upload_title">Photo</div>
            <div className="event-page__block-upload">
              <div className="event-page__main-block-upload_container">
                <div {...getRootProps()} className="block-upload">
                  <input {...getInputProps()} />
                  {files !== null && <img className="event-page__block-upload_img" src={files[0].preview} />}
                  {imagePath.length > 0 && files === null && (
                    <img
                      className="event-page__block-upload_img"
                      src={`https://file-storage-dev.s3.eu-north-1.amazonaws.com/${imagePath}`}
                    />
                  )}
                  <div className="block-upload__image" />
                  <div className="block-upload__container-text">
                    <div className="block-upload__container-text_part-1">Click to Upload File</div>
                    <div className="block-upload__container-text_part-2">or Drag and drop files here</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="event-page__main-block">
            <div className="event-page__main_input-container">
              <div className="event-page__main_input-container-title">Event Title</div>
              <UniqueInput item={title} setItem={setTitle} placeStr="f.e. Lorem" type={0} />
            </div>
            <div className="event-page__main_input-container">
              <div className="event-page__main_input-container-title">Event Address</div>
              <UniqueInput item={address} setItem={setAddress} placeStr="f.e. Lorem" type={2} />
            </div>
            <div className="event-page__main_input-container">
              <div className="event-page__main_input-container-title">Event Location</div>
              <UniqueInput item={location} setItem={setLocation} placeStr="f.e. Lorem" type={2} />
            </div>
          </div>
          <div className="event-page__main-block">
            <div className="event-page__main_input-container">
              <div className="event-page__main_input-container-title">Subtitle</div>
              <UniqueInput item={subtitle} setItem={setSubtilte} placeStr="f.e. Lorem" type={0} />
            </div>
            <div className="event-page__main_input-container">
              <div className="event-page__main_input-container-title">Date of the event</div>
              <UniqueInput item={date} setItem={setDate} placeStr="f.e. Lorem" type={0} />
            </div>
            <div className="event-page__main_input-container">
              <div className="event-page__main_input-container-title">Day and time of the event</div>
              <UniqueInput item={daytime} setItem={setDaytime} placeStr="f.e. Lorem" type={0} />
            </div>
          </div>
          <div className="event-page__main-block_message">
            {!message.isHide && (
              <div className={message.isSuccess ? 'success-message' : 'error-message'}>{message.message}</div>
            )}
          </div>
          <div className="event-page__main-block_button-save" onClick={onSendForm}>
            Save
          </div>
          <div className="event-page__main-block-navigate_button">
            <NavigateButton navigateTo={EVENT_SHEDULE_ROUTE} buttonData={'Schedule'} />
            <NavigateButton navigateTo={EVENT_MAP_ROUTE} buttonData={'Map'} />
            <NavigateButton navigateTo={EVENT_ARTIST_ROUTE} buttonData={'Artists'} />
          </div>
        </div>
      </div>
    </div>
  );
};
