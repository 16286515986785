import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { getGoodById, editGoodById } from '../../api/shop';
import { Button, FileUploader, Input } from '../common';
import { StyledModal } from './styled';
import { GoodData } from './types';
import { ModalIconClose } from '../common/icons';
import ModalUnsavedDataVariant from '../common/ModalUnsavedDataVarianWithoutRedux';

const validateSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  externalLink: Yup.string().required('External link is required'),
  image: Yup.string().required('Image is required'),
});

export const ModalEditGood = (props: any): JSX.Element => {
  const { goodId } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenSaveModal, setIsOpenSaveModal] = React.useState(false);
  const [item, setItem] = React.useState<GoodData>({ title: '', externalLink: '', image: '' });
  const [imageKey, setImageKey] = React.useState<string>('');
  const [isDirty, setIsDirty] = React.useState(false);

  if (!goodId) {
    return <></>;
  }

  const openModal = () => setIsOpen(true);
  const toggleModal = () => {
    if (isDirty) {
      setIsOpenSaveModal(true);
    } else {
      setIsOpen(false);
    }
  };

  const getGoods = async () => {
    const item = await getGoodById(goodId);
    setItem(item);
  };

  React.useEffect(() => {
    if (isOpen) {
      getGoods();
    }
  }, [isOpen]);

  const initialValues: GoodData = {
    title: item.title,
    externalLink: item.externalLink,
    image: item.image,
  };

  return (
    <>
      <Button onClick={openModal} name="Edit" color="#00ADEF" />
      <ModalUnsavedDataVariant isOpen={isOpenSaveModal} setIsOpen={setIsOpenSaveModal} closeMainModal={setIsOpen} />
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <div className="modalHeader">
          <div>Edit Item</div>
          <div className="iconButton" onClick={toggleModal}>
            <ModalIconClose />
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={async values => {
            const formValues = {
              ...values,
              image: `${imageKey || item.image}`,
            };

            await editGoodById(goodId, formValues);
            await toggleModal();
            await location.reload();
          }}
        >
          {(formik: any) => {
            const { isSubmitting, handleSubmit, touched, errors, dirty } = formik;

            React.useEffect(() => {
              setIsDirty(dirty);
            }, [dirty]);

            return (
              <Form className="col" onSubmit={handleSubmit}>
                <div className="modalBody">
                  <div className="col">
                    <Input name="title" label="Title" placeholder="f.e. Item" />
                    <Input name="externalLink" label="Link" placeholder="f.e. https://google.com/" />
                    <label className="label">Image</label>
                    <FileUploader name="image" imagePath={item?.image} setImageKey={setImageKey} />
                    {touched.image && errors.image ? <div className="error">{errors.image}</div> : null}
                  </div>
                </div>

                <div className="modalFooter">
                  <Button onClick={toggleModal} name="Cancel" color="#00ADEF" type="button" />
                  <Button type="submit" name="Save" background="#00ADEF" color="white" disabled={isSubmitting} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </StyledModal>
    </>
  );
};
