import { axiosPrivate } from './axios';

export const fetchArtists = async (query: string) => {
  const { data } = await axiosPrivate.get(`/artists?search=${query}`);
  return data;
};

export const getArtistById = async (id: string) => {
  const { data } = await axiosPrivate.get(`/artists/${id}`);
  return data;
};

export const addNewArtist = async (artistData: any) => {
  await axiosPrivate.post('/artists', artistData);
};

export const editArtistById = async (id: string, artistData: any) => {
  await axiosPrivate.patch(`/artists/${id}`, artistData);
};

export const deleteArtistById = async (id: string) => {
  await axiosPrivate.delete(`/artists/${id}`);
};
