export const TimeLogo = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 7.61537V12.5481L15.2885 15.2884M19.125 12C19.125 15.935 15.935 19.125 12 19.125C8.06497 19.125 4.875 15.935 4.875 12C4.875 8.06497 8.06497 4.875 12 4.875C15.935 4.875 19.125 8.06497 19.125 12Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PersonLogo = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0043 17.7439L18.2681 18.1687H18.2681L18.0043 17.7439ZM5.99456 17.7439L6.2584 17.3192H6.2584L5.99456 17.7439ZM5.42782 16.5422L5.92599 16.585L5.42782 16.5422ZM18.571 16.5422L18.0728 16.585V16.585L18.571 16.5422ZM18.0495 14.7023L17.5934 14.9071V14.9071L18.0495 14.7023ZM5.94927 14.7023L5.49315 14.4975H5.49315L5.94927 14.7023ZM14.7879 8.16346C14.7879 9.70349 13.5394 10.9519 11.9994 10.9519V11.9519C14.0917 11.9519 15.7879 10.2558 15.7879 8.16346H14.7879ZM11.9994 10.9519C10.4594 10.9519 9.21095 9.70349 9.21095 8.16346H8.21095C8.21095 10.2558 9.9071 11.9519 11.9994 11.9519V10.9519ZM9.21095 8.16346C9.21095 6.62344 10.4594 5.375 11.9994 5.375V4.375C9.9071 4.375 8.21095 6.07115 8.21095 8.16346H9.21095ZM11.9994 5.375C13.5394 5.375 14.7879 6.62344 14.7879 8.16346H15.7879C15.7879 6.07115 14.0917 4.375 11.9994 4.375V5.375ZM17.7404 17.3192C16.9509 17.8097 15.1659 18.625 11.9994 18.625V19.625C15.3479 19.625 17.3164 18.7599 18.2681 18.1687L17.7404 17.3192ZM11.9994 18.625C8.83293 18.625 7.04791 17.8097 6.2584 17.3192L5.73072 18.1687C6.68242 18.7599 8.65093 19.625 11.9994 19.625V18.625ZM4.92965 16.4994C4.87442 17.143 5.14195 17.8029 5.73072 18.1687L6.2584 17.3192C6.03242 17.1788 5.89837 16.9068 5.92599 16.585L4.92965 16.4994ZM18.0728 16.585C18.1005 16.9068 17.9664 17.1788 17.7404 17.3192L18.2681 18.1687C18.8569 17.8029 19.1244 17.143 19.0692 16.4994L18.0728 16.585ZM17.5934 14.9071C17.8168 15.4045 18.023 16.0047 18.0728 16.585L19.0692 16.4995C19.0058 15.7612 18.7514 15.0446 18.5057 14.4975L17.5934 14.9071ZM5.92599 16.585C5.9758 16.0047 6.18204 15.4045 6.40539 14.9071L5.49315 14.4975C5.24745 15.0446 4.99301 15.7612 4.92965 16.4994L5.92599 16.585ZM16.2771 13.1442H7.72168V14.1442H16.2771V13.1442ZM6.40539 14.9071C6.6164 14.4372 7.12388 14.1442 7.72168 14.1442V13.1442C6.81613 13.1442 5.898 13.5959 5.49315 14.4975L6.40539 14.9071ZM18.5057 14.4975C18.1008 13.5959 17.1827 13.1442 16.2771 13.1442V14.1442C16.8749 14.1442 17.3824 14.4372 17.5934 14.9071L18.5057 14.4975Z"
      fill="black"
    />
  </svg>
);

export const NotificationsLogo = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.125 13.4904C19.4011 13.4904 19.625 13.7142 19.625 13.9904C19.625 14.2665 19.4011 14.4904 19.125 14.4904L19.125 13.4904ZM4.875 14.4904C4.59886 14.4904 4.375 14.2665 4.375 13.9904C4.375 13.7142 4.59886 13.4904 4.875 13.4904L4.875 14.4904ZM14.8735 10.5271L14.3779 10.4611L14.8735 10.5271ZM16.0503 10.4609L16.5354 10.3396L16.0503 10.4609ZM11.5294 13.1308L12.0283 13.0983L11.5294 13.1308ZM11.3163 9.85688L11.8152 9.8244L11.3163 9.85688ZM11.0304 13.1633L10.8173 9.88935L11.8152 9.8244L12.0283 13.0983L11.0304 13.1633ZM14.0174 13.1655L14.3779 10.4611L15.3691 10.5932L15.0087 13.2976L14.0174 13.1655ZM16.5354 10.3396L17.039 12.3542L16.0689 12.5967L15.5652 10.5821L16.5354 10.3396ZM18.4942 13.4904L19.125 13.4904L19.125 14.4904L18.4942 14.4904L18.4942 13.4904ZM8.83334 9.78825L8.39266 12.4055L7.40654 12.2394L7.84722 9.62221L8.83334 9.78825ZM5.92736 14.4904L4.875 14.4904L4.875 13.4904L5.92736 13.4904L5.92736 14.4904ZM17.039 12.3542C17.206 13.0219 17.8059 13.4904 18.4942 13.4904L18.4942 14.4904C17.3471 14.4904 16.3471 13.7096 16.0689 12.5967L17.039 12.3542ZM14.3779 10.4611C14.5381 9.25868 16.2412 9.16276 16.5354 10.3396L15.5652 10.5821C15.5547 10.54 15.5388 10.5277 15.5308 10.5223C15.5177 10.5134 15.4939 10.5048 15.4626 10.5065C15.4313 10.5083 15.4086 10.5195 15.3966 10.5299C15.3893 10.5361 15.3748 10.5501 15.3691 10.5932L14.3779 10.4611ZM8.39266 12.4055C8.19 13.6091 7.14788 14.4904 5.92736 14.4904L5.92736 13.4904C6.65967 13.4904 7.28494 12.9616 7.40654 12.2394L8.39266 12.4055ZM12.0283 13.0983C12.1067 14.3036 13.8579 14.3627 14.0174 13.1655L15.0087 13.2976C14.6895 15.692 11.1873 15.5737 11.0304 13.1633L12.0283 13.0983ZM10.8173 9.88935C10.7402 8.70473 9.03044 8.61763 8.83334 9.78825L7.84722 9.62221C8.24143 7.28093 11.661 7.45521 11.8152 9.8244L10.8173 9.88935Z"
      fill="black"
    />
  </svg>
);
