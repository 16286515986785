import { axiosPrivate } from './axios';

//BLOCKS

export const addNewBlock = async (data: any) => {
  await axiosPrivate.post('/shop', data);
};

export const fetchBlocks = async (query?: string) => {
  const { data } = await axiosPrivate.get(query ? `/shop?search=${query}` : '/shop');
  return data;
};

export const getBlockById = async (id: string) => {
  const { data } = await axiosPrivate.get(`/shop/${id}`);
  return data;
};

export const editBlockById = async (id: string, data: any) => {
  await axiosPrivate.patch(`/shop/${id}`, data);
};

export const deleteBlockById = async (id: string) => {
  await axiosPrivate.delete(`/shop/${id}`);
};

// GOODS

export const addNewGood = async (blockId: string, data: any) => {
  await axiosPrivate.post(`/shop/${blockId}/goods`, data);
};

export const fetchGoodsById = async (id: string, query?: string) => {
  const { data } = await axiosPrivate.get(query ? `/shop/${id}/goods?search=${query}` : `/shop/${id}/goods`);
  return data;
};

export const getGoodById = async (id: string) => {
  const { data } = await axiosPrivate.get(`/goods/${id}`);
  return data;
};

export const editGoodById = async (id: string, data: any) => {
  await axiosPrivate.patch(`/goods/${id}`, data);
};

export const deleteGoodById = async (id: string) => {
  await axiosPrivate.delete(`/goods/${id}`);
};
