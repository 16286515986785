import React, { useState, useEffect, useCallback } from 'react';
import { AddNewArtistModal, EditArtistModal } from './';
import { deleteArtistById, fetchArtists } from '../../../api/artists';
import { SearchInput, ModalDelete } from '../../common';
import { Page, PageItem } from 'src/styled';
import { ArtistData, ArtistsData } from './types';

export const EventArtists = () => {
  const [searchInput, setSearchInput] = useState('');
  const [artists, setArtists] = useState<ArtistsData>([]);

  const getArtists = async () => {
    const artists = await fetchArtists(searchInput);
    setArtists(artists);
  };

  const filterDataByTitle = (data: any, name: string): any => {
    return data.filter((item: any) => {
      if (item.name.toLowerCase().includes(name.toLowerCase())) {
        return item;
      }
    });
  };

  useEffect(() => {
    getArtists();
  }, [deleteArtistById]);

  if (!artists) return null;

  const handleSearch = useCallback(() => {
    getArtists();
  }, [searchInput]);

  const handleDelete = async (id: string) => {
    await deleteArtistById(id);
  };

  const mainArtists = artists
    .filter(artist => artist.isPrimary === true)
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

  const otherArtists = artists
    .filter(artist => artist.isPrimary === false)
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

  return (
    <Page>
      <div className="breadCrumbs">
        <a href="/" title="Event">
          Om LS
        </a>
        Artister
      </div>
      <div className="pageTitle">Artister</div>
      <div className="header">
        <SearchInput value={searchInput} onEnter={handleSearch} onInput={(value: string) => setSearchInput(value)} />
        <AddNewArtistModal />
      </div>
      {filterDataByTitle(mainArtists, searchInput).length > 0 ? (
        <>
          <div className="listHeader" title="Main Artists">
            Headliner
          </div>
          <div className="listFlex">
            {filterDataByTitle(mainArtists, searchInput)?.map((item: ArtistData, index: number) => {
              return (
                <PageItem key={index}>
                  <div className="item-header">{item.name}</div>
                  <div className="item-body">
                    <EditArtistModal artistId={item.id} />
                    <ModalDelete handleDelete={handleDelete} id={`${item.id}`} />
                  </div>
                </PageItem>
              );
            })}
          </div>
        </>
      ) : (
        <div className="message-no_items">Headliner artists list is empty...</div>
      )}
      {filterDataByTitle(otherArtists, searchInput).length > 0 ? (
        <>
          <div className="listHeader">Other</div>
          <div className="listFlex">
            {filterDataByTitle(otherArtists, searchInput).map((item: ArtistData, index: number) => {
              return (
                <PageItem key={index}>
                  <div className="item-header">{item.name}</div>
                  <div className="item-body">
                    <EditArtistModal artistId={item.id} />
                    <ModalDelete handleDelete={handleDelete} id={`${item.id}`} />
                  </div>
                </PageItem>
              );
            })}
          </div>
        </>
      ) : (
        <div className="message-no_items">Other artists list is empty...</div>
      )}
    </Page>
  );
};
