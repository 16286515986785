import { axiosPrivate } from './axios';

export const fetchArticles = async (query: string) => {
  const { data } = await axiosPrivate.get(`/articles?search=${query}`);
  return data;
};

export const getArticleById = async (id: string) => {
  const { data } = await axiosPrivate.get(`/articles/${id}`);
  return data;
};

export const addNewArticle = async (articleData: any) => {
  await axiosPrivate.post('/articles', articleData);
};

export const editArticleById = async (id: string, articleData: any) => {
  await axiosPrivate.patch(`/articles/${id}`, articleData);
};

export const deleteArticleById = async (id: string) => {
  await axiosPrivate.delete(`/articles/${id}`);
};
