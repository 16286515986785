export const LogoSideBar = () => (
  <svg width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.26 13.6584V9.75238L17.5979 14.6328V26.3424L28.3484 22.764V26.67L17.5979 30.2484L6.84736 26.67V11.0544L2.93579 9.75238V29.274L17.5979 34.1544L32.26 29.274V17.556L21.5094 21.1428V17.2368L32.26 13.6584Z"
      fill="black"
    />
    <path d="M17.5979 2.93158L2.93579 7.81198L17.5979 12.6924L32.26 7.81198L17.5979 2.93158Z" fill="black" />
    <path
      d="M35.1958 31.2312V5.8548L17.5979 0L0 5.8548V31.2312L17.5979 37.086L35.1958 31.2312ZM0.984202 6.5016L17.5979 0.966L34.2115 6.5016V30.576L17.5979 36.1116L0.984202 30.576V6.5016Z"
      fill="black"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4851 15.0154C14.6317 15.1617 14.8691 15.1615 15.0154 15.0149C15.1617 14.8683 15.1615 14.6309 15.0149 14.4846L14.4851 15.0154ZM7.22115 12.8173C4.13048 12.8173 1.625 10.3118 1.625 7.22115H0.875C0.875 10.726 3.71627 13.5673 7.22115 13.5673V12.8173ZM1.625 7.22115C1.625 4.13048 4.13048 1.625 7.22115 1.625V0.875C3.71627 0.875 0.875 3.71627 0.875 7.22115H1.625ZM7.22115 1.625C10.3118 1.625 12.8173 4.13048 12.8173 7.22115H13.5673C13.5673 3.71627 10.726 0.875 7.22115 0.875V1.625ZM12.8173 7.22115C12.8173 8.76804 12.1902 10.1677 11.1754 11.1811L11.7053 11.7118C12.8552 10.5635 13.5673 8.97514 13.5673 7.22115H12.8173ZM11.1754 11.1811C10.1625 12.1925 8.76514 12.8173 7.22115 12.8173V13.5673C8.97184 13.5673 10.5576 12.8579 11.7053 11.7118L11.1754 11.1811ZM11.1754 11.7118L14.4851 15.0154L15.0149 14.4846L11.7053 11.181L11.1754 11.7118Z"
      fill="#929292"
    />
  </svg>
);

export const UploaderIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5 14V16.6667C18.5 17.0203 18.3595 17.3594 18.1095 17.6095C17.8594 17.8595 17.5203 18 17.1667 18H7.83333C7.47971 18 7.14057 17.8595 6.89052 17.6095C6.64048 17.3594 6.5 17.0203 6.5 16.6667V14"
      stroke="#009EF7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.5 11L12.5 14L15.5 11" stroke="#009EF7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5 14L12.5 6" stroke="#009EF7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export const ArrowRight = () => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 1 4.002 L 10.998 4 M 10.998 4 L 8.306 1.307 M 10.998 4 L 8.306 6.693"
      stroke="#929292"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MapIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.25 10.3558C14.25 11.5666 13.2426 12.5481 12 12.5481C10.7574 12.5481 9.75 11.5666 9.75 10.3558C9.75 9.14501 10.7574 8.16348 12 8.16348C13.2426 8.16348 14.25 9.14501 14.25 10.3558Z"
      stroke="black"
      strokeLinejoin="round"
    />
    <path
      d="M17.625 10.1871C17.625 14.8073 12 19.125 12 19.125C12 19.125 6.375 14.8073 6.375 10.1871C6.375 7.25332 8.8934 4.875 12 4.875C15.1066 4.875 17.625 7.25332 17.625 10.1871Z"
      stroke="black"
      strokeLinejoin="round"
    />
  </svg>
);

export const ModalIconClose = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 7L16 16M16 16L7 25M16 16L25 25M16 16L25 7"
      stroke="#929292"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
