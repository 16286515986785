import {
  CHANGED_EVENT_DATA,
  CHANGED_INFO_DATA,
  MODAL_RELOAD_PAGE,
  NAVIGATE_TO_URL,
  TOGGLE_EVENT_MODAL,
  TOGGLE_INFO_MODAL,
} from '../const/modal';
import { initialModalData, ModalSavingData } from '../types/modal';

const initialState: initialModalData = {
  isChangedEventData: false,
  isOpenModalEvent: false,
  isChangedInfoData: false,
  isOpenModalInfo: false,
  navigateTo: '',
  reloadPage: false,
};

export const modalReducer = (state = initialState, action: ModalSavingData) => {
  switch (action.type) {
    case CHANGED_EVENT_DATA:
      return { ...state, isChangedEventData: action.payload };
    case CHANGED_INFO_DATA:
      return { ...state, isChangedInfoData: action.payload };
    case TOGGLE_EVENT_MODAL:
      return { ...state, isOpenModalEvent: action.payload };
    case TOGGLE_INFO_MODAL:
      return { ...state, isOpenModalInfo: action.payload };
    case NAVIGATE_TO_URL:
      return { ...state, navigateTo: action.payload };
    case MODAL_RELOAD_PAGE:
      return { ...state, reloadPage: action.payload };
    default:
      return { ...state };
  }
};
