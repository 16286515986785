import { Editor } from 'src/components/common';

const FormikControl = (props: any) => {
  const { control, ...rest } = props;

  switch (control) {
    case 'editor':
      return <Editor {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
