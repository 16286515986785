import React, { useState } from 'react';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useActions } from '../../hooks/useActions';
import { useTypeSelector } from '../../hooks/useTypeSelectors';

const Auth = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const styles = {
    test: {
      height: '90vh',
    },
  };

  const actions = useActions();
  const isLogin = useTypeSelector(item => item.user.isLogin);
  const errorMessage = useTypeSelector(item => item.user.error);
  const loginUser = () => {
    actions.loginUser(email, password);
  };
  return (
    <Row className="align-content-center justify-content-center" style={styles.test}>
      <Col md={3}>
        <Card>
          <h2 className="m-auto">LGA Admin</h2>
          <Form className="d-flex flex-column mx-2">
            <Form.Control
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="mt-2"
              placeholder="Email"
              name="email"
            />
            <Form.Control
              value={password}
              onChange={e => setPassword(e.target.value)}
              className="mt-2"
              placeholder="Password"
              name="password"
              type="password"
            />
            <Button onClick={loginUser} className="my-2 align-self-end" variant="outline-dark" type="reset">
              Sign in
            </Button>
            {isLogin === false && (
              <Alert style={{ padding: '2px', paddingTop: '5px', paddingLeft: '5px' }} variant="danger">
                <Alert.Heading style={{ fontSize: '12px' }}>{errorMessage}</Alert.Heading>
              </Alert>
            )}
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Auth;
