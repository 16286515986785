import React from 'react';
import styled from 'styled-components';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import {
  BLOG_ROUTE,
  HOME_ROUTE,
  SHOP_BLOCKS_ROUTE,
  OFFERS_ROUTE,
  INFO_MAIN_ROUTE,
  NOTIFICATIONS_CENTER_ROUTE,
} from '../../utils/consts';
import { LogoSideBar } from './icons';
import { useTypeSelector } from 'src/hooks/useTypeSelectors';
import { useActions } from 'src/hooks/useActions';

const Logo = styled.div`
  margin-left: 18px;
`;

const SideBar = (): JSX.Element => {
  const isChangedEvent = useTypeSelector(item => item.modal.isChangedEventData);
  const isChangedInfo = useTypeSelector(item => item.modal.isChangedInfoData);
  const actions = useActions();
  const location = useLocation();
  const openModal = (navigateTo: string, reload?: boolean, isLogo?: boolean) => {
    if (navigateTo !== location.pathname || isLogo) {
      if (isChangedEvent || isChangedInfo) {
        actions.toggleModalEvent(true);
        actions.toggleModalInfo(true);
        actions.navigateToUrl(navigateTo);
        if (reload) {
          actions.reloadPage(true);
        }
      }
    }
  };

  return (
    <>
      <Nav
        className="d-none d-md-block sidebar"
        activeKey="/"
        onSelect={selectedKey => alert(`selected ${selectedKey}`)}
      >
        <Navbar.Brand
          onClick={() => openModal(HOME_ROUTE, false, true)}
          href={isChangedEvent || isChangedInfo ? '' : HOME_ROUTE}
        >
          <span style={{ maxHeight: '70px' }}>
            <Logo>
              <LogoSideBar />
            </Logo>
          </span>
        </Navbar.Brand>
        <div className="sidebar__nav-item__container">
          <Nav.Item>
            <NavLink
              onClick={() => openModal(HOME_ROUTE)}
              to={isChangedEvent || isChangedInfo ? location.pathname : '/'}
              className={'/' === location.pathname ? 'sidebar__link  sidebar__link__active' : 'sidebar__link'}
            >
              Om LS
            </NavLink>
          </Nav.Item>

          <Nav.Item>
            <NavLink
              onClick={() => openModal(BLOG_ROUTE)}
              to={isChangedEvent || isChangedInfo ? location.pathname : BLOG_ROUTE}
              className={location.pathname === BLOG_ROUTE ? 'sidebar__link  sidebar__link__active' : 'sidebar__link'}
            >
              Nyheter
            </NavLink>
          </Nav.Item>

          <Nav.Item>
            <NavLink
              onClick={() => openModal(OFFERS_ROUTE)}
              to={isChangedEvent || isChangedInfo ? location.pathname : OFFERS_ROUTE}
              className={location.pathname === OFFERS_ROUTE ? 'sidebar__link  sidebar__link__active' : 'sidebar__link'}
            >
              Editors Choice
            </NavLink>
          </Nav.Item>

          <Nav.Item>
            <NavLink
              onClick={() => openModal(SHOP_BLOCKS_ROUTE)}
              to={isChangedEvent || isChangedInfo ? location.pathname : SHOP_BLOCKS_ROUTE}
              className={
                location.pathname === SHOP_BLOCKS_ROUTE ? 'sidebar__link sidebar__link__active' : 'sidebar__link'
              }
            >
              Kjøp
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink
              onClick={() => openModal(NOTIFICATIONS_CENTER_ROUTE)}
              to={isChangedEvent || isChangedInfo ? location.pathname : NOTIFICATIONS_CENTER_ROUTE}
              className={
                location.pathname === NOTIFICATIONS_CENTER_ROUTE
                  ? 'sidebar__link sidebar__link__active'
                  : 'sidebar__link'
              }
            >
              Notifications center
            </NavLink>
          </Nav.Item>

          <Nav.Item>
            <NavLink
              onClick={() => openModal(INFO_MAIN_ROUTE)}
              to={isChangedEvent || isChangedInfo ? location.pathname : INFO_MAIN_ROUTE}
              className={
                location.pathname === INFO_MAIN_ROUTE ? 'sidebar__link  sidebar__link__active' : 'sidebar__link'
              }
            >
              Info
            </NavLink>
          </Nav.Item>
        </div>
      </Nav>
    </>
  );
};
export default SideBar;
