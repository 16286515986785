import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { fetchInfo, editInfoById } from '../../api/info';
import { infoMenu, validateSchema } from './helpers';
import { InfoData, InfoMenu } from './types';
import { Button, Input } from '../common';
import { Page, MenuListItem } from 'src/styled';
import { StyledFormBody, StyledFormFooter } from './styled';
import { EditModal } from './EditModal';
import { MapIcon } from '../common/icons';
import { useActions } from 'src/hooks/useActions';
import ModalUnsavedData from '../common/ModalUnsavedData';
import { useTypeSelector } from 'src/hooks/useTypeSelectors';

export const InfoPage = (): JSX.Element => {
  const actions = useActions();
  const [infoData, setInfoData] = React.useState<InfoData>({
    id: '',
    FacebookLink: '',
    InstagramLink: '',
    address: '',
    bussinessName: '',
    email: '',
    messangerLink: '',
    phone: '',
  });
  const isOpenModalInfo = useTypeSelector(item => item.modal.isOpenModalInfo);

  const getInfo = async () => {
    await fetchInfo()
      .then(item => {
        setInfoData(item.data[0]);
      })
      .catch(err => console.log(err));
  };

  React.useEffect(() => {
    getInfo();
  }, []);

  if (!infoData) return <></>;

  const initialValues: InfoData = {
    id: infoData.id,
    FacebookLink: infoData.FacebookLink,
    InstagramLink: infoData.InstagramLink,
    address: infoData.address,
    bussinessName: infoData.bussinessName,
    email: infoData.email,
    messangerLink: infoData.messangerLink,
    phone: infoData.phone,
  };

  return (
    <Page>
      <ModalUnsavedData isOpen={isOpenModalInfo} />
      <div className="pageTitle">Info</div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validateSchema}
        onSubmit={async values => {
          await editInfoById(`${infoData.id}`, values);
          await location.reload();
        }}
      >
        {formik => {
          const { handleSubmit, isSubmitting, dirty } = formik;
          useEffect(() => {
            if (dirty) {
              actions.changedInfoData(true);
            }
          }, [dirty]);

          return (
            <Form className="col" onSubmit={handleSubmit}>
              <StyledFormBody>
                <Input name="bussinessName" label="Bussiness Name" placeholder="Type here" />
                <Input name="address" label="Address" placeholder="Type here" rightIcon={<MapIcon />} />
                <Input name="FacebookLink" label="Facebook Link" placeholder="https://facebook.com..." />
                <Input name="phone" label="Phone" placeholder="+37200..." />
                <Input name="InstagramLink" label="Instagram Link" placeholder="https://instagram.com..." />
                <Input name="messangerLink" label="Messanger Link" placeholder="Type here" />
                <Input name="email" label="Email" placeholder="Type here" />
              </StyledFormBody>
              <StyledFormFooter>
                <Button type="submit" name="Save" background="#00ADEF" color="white" disabled={isSubmitting} />
              </StyledFormFooter>
            </Form>
          );
        }}
      </Formik>

      <div className="list-three-columns">
        {infoMenu?.map((item: InfoMenu, index: number): JSX.Element => {
          return (
            <MenuListItem key={index}>
              <div className="item-header">{item.title}</div>
              <div className="item-body">
                <EditModal modalName={item?.title} getData={item.getData} editData={item.editData} />
              </div>
            </MenuListItem>
          );
        })}
      </div>
    </Page>
  );
};
