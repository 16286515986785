import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { StyledModal } from './styled';
import { addNewArtist } from '../../../api/artists';
import { ArtistData } from './types';
import { FileUploader, Button, Input, Checkbox, Textarea } from 'src/components/common';
import { ModalIconClose } from 'src/components/common/icons';

const validateSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  isPrimary: Yup.boolean().required('Main artist is required'),
  artistInfo: Yup.string().required('Artist text is required'),
  imagePath: Yup.string().required('Artist cover is required'),
});

export const AddNewArtistModal = (): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [imageKey, setImageKey] = React.useState<string>('');
  const initialValues: ArtistData = {
    name: '',
    isPrimary: false,
    artistInfo: '',
    imagePath: '',
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Button
        onClick={toggleModal}
        name="Legg til ny artist"
        title="Add New Artist"
        background="#00ADEF"
        color="white"
      />
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <div className="modalHeader">
          <div title="Add New Artist">Legg til ny artist</div>
          <div className="iconButton" onClick={toggleModal}>
            <ModalIconClose />
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={async values => {
            const formValues = {
              ...values,
              imagePath: `${imageKey}`,
            };

            await addNewArtist(formValues);
            await toggleModal();
            await location.reload();
          }}
        >
          {(formik: any) => {
            React.useEffect(() => {
              formik.setFieldValue('imagePath', imageKey);
            }, [imageKey]);

            return (
              <Form className="col" onSubmit={formik.handleSubmit}>
                <div className="modalBody">
                  <div className="twoColumns">
                    <div className="col">
                      <Input name="name" label="Name" placeholder="f.e. Lady Gaga" />
                      <Checkbox name="isPrimary" label="Headliner" checked={formik.values?.isPrimary} />
                    </div>
                    <div className="col">
                      <label className="label">Artist cover</label>
                      <FileUploader imagePath={imageKey || ''} setImageKey={setImageKey} />
                      {formik.touched.imagePath && formik.errors.imagePath ? (
                        <div className="error">{formik.errors.imagePath}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col">
                    <Textarea name="artistInfo" label="Artist info" placeholder="Text" />
                  </div>
                </div>

                <div className="modalFooter">
                  <Button onClick={toggleModal} name="Cancel" color="#00ADEF" />
                  <Button type="submit" name="Save" background="#00ADEF" disabled={formik.isSubmitting} color="white" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </StyledModal>
    </div>
  );
};
