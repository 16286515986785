import styled from 'styled-components';

export const Page = styled.div`
  padding: 24px 64px 24px 24px;

  .breadCrumbs {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #929292;

    a {
      display: flex;
      text-decoration: none;
      color: #929292;

      :hover {
        color: #00adef;
      }

      ::after {
        display: flex;
        height: 20px;
        align-items: center;
        content: url('/img/breadcrumb-arrow.svg');
        padding: 0 10px;
      }
    }
  }

  .pageTitle {
    font-size: 32px;
    font-weight: 700;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
  }

  .listHeader {
    padding: 16px 0;
  }

  .listFlex {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }

  .list-three-columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 5px;
    justify-items: stretch;
    align-items: stretch;
  }

  .message-no_items {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 30px;
    font-weight: bold;
  }
`;

export const PageItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  padding: 24px;
  min-width: 330px;
  max-width: 350px;

  .item-header {
    font-weight: 700;
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item-body {
    display: flex;
    gap: 20px;
  }
`;

export const MenuListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  padding: 24px;

  .item-header {
    font-weight: 700;
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item-body {
    display: flex;
    gap: 20px;
  }
`;
