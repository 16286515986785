import React from 'react';
import { deleteSchedule } from 'src/api/event-schedule';

interface tableItemInterface {
  time: string;
  artist: string;
  scene: string;
  showModal: () => void;
  id: string;
  updateData: (item: any) => void;
  setUpdateIdItem: (id: string) => void;
}

const TableItem = ({ time, artist, scene, showModal, id, updateData, setUpdateIdItem }: tableItemInterface) => {
  const newTime = new Date(time);
  const removeItem = () => {
    deleteSchedule(id)
      .then(() => {
        updateData((prev: any) => [...prev, 1]);
      })
      .catch(err => console.log(err));
  };

  const updateItem = () => {
    showModal();
    setUpdateIdItem(id);
  };

  const checkDateLength = (check: string) => {
    return check.length === 1 ? 0 + check : check;
  };

  return (
    <div className="table-item">
      <div className="table-item__time">
        {`
          ${newTime.getDate().toString()}
          ${newTime.toString().slice(4, 7)}
          ${checkDateLength(newTime.getFullYear().toString())}
          / ${checkDateLength(newTime.getHours().toString())}
          : ${checkDateLength(newTime.getMinutes().toString())}
        `}
      </div>
      <div className="table-item__artist">{artist}</div>
      <div className="table-item__scene">{scene}</div>
      <div className="table-item__button">
        <div className="table-item__button_edit" onClick={updateItem}>
          Edit
        </div>
        <div className="table-item__button_remove" onClick={removeItem}>
          Delete
        </div>
      </div>
    </div>
  );
};

export default TableItem;
