import React from 'react';
import { AddOfferModal, EditOfferModal } from './';
import { deleteOfferById, fetchOffers } from '../../api/offers';
import { ModalDelete, SearchInput } from '../common';
import { Page, PageItem } from 'src/styled';
import { OfferData } from './types';

export const SpecialOffers = () => {
  const [searchInput, setSearchInput] = React.useState('');
  const [offers, setOffers] = React.useState<OfferData[]>([]);

  const filterDataByTitle = (data: any, title: string): any => {
    return data.filter((item: any) => {
      if (item.title.toLowerCase().includes(title.toLowerCase())) {
        return item;
      }
    });
  };

  const getOffers = async () => {
    const offers = await fetchOffers(searchInput);
    setOffers(offers);
  };

  React.useEffect(() => {
    getOffers();
  }, [deleteOfferById]);

  if (!offers) return null;

  const handleSearch = React.useCallback(() => {
    getOffers();
  }, [searchInput]);

  const handleDelete = async (id: string) => {
    await deleteOfferById(id);
  };

  return (
    <Page>
      <div className="pageTitle" title="Special offers">
        Editors Choice
      </div>
      <div className="header">
        <SearchInput value={searchInput} onEnter={handleSearch} onInput={(value: string) => setSearchInput(value)} />
        <AddOfferModal />
      </div>
      <div className="listFlex">
        {filterDataByTitle(offers, searchInput).length > 0 ? (
          filterDataByTitle(offers, searchInput).map((item: OfferData, index: number) => {
            return (
              <PageItem key={index}>
                <div className="item-header">{item.title}</div>
                <div className="item-body">
                  <EditOfferModal offerId={item.id} />
                  <ModalDelete handleDelete={handleDelete} id={`${item.id}`} />
                </div>
              </PageItem>
            );
          })
        ) : (
          <div className="message-no_items">Spesialtilbud er tomme...</div>
        )}
      </div>
    </Page>
  );
};
