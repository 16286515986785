import React from 'react';
import styled from 'styled-components';
import { SearchIcon } from './icons';

const Form = styled.form`
  width: 300px;
  outline: none;
  border: none;
  position: relative;

  .formInput {
    width: 100%;
    border: 1px solid #ebebeb;
    outline: none;
    border-radius: 8px;
    padding: 8px 0;
    padding-left: 40px;
    padding-right: 16px;

    ::placeholder {
    }
  }
`;

const Button = styled.button`
  position: absolute;
  left: 0;
  top: 12px;
  margin-left: 14px;
  border: none;
  background: none;
  display: flex;
`;

export function SearchInput({ value, onInput, onEnter }: any) {
  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        onEnter();
      }}
      autoComplete="off"
    >
      <input
        className="formInput"
        type="text"
        id="search"
        value={value}
        onChange={e => onInput(e.target.value)}
        placeholder="Type your search"
      />
      <Button type="submit">
        <SearchIcon />
      </Button>
    </Form>
  );
}
