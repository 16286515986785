import Modal from 'styled-react-modal';

export const StyledModal = Modal.styled`
  font-family: Transat, sans-serif;
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 16px;
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 700;  
    width: 100%;
    padding: 24px;
    border-bottom: 1px solid #ebebeb;
  }

  .modalBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
  }

  .modalFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 24px;
    border-top: 1px solid #ebebeb;
  }

  .col {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
  
  .col > .label {
    padding-bottom: 8px;
  }

  .col > .error {
    padding-top: 8px;
  }
  
  .twoColumns {
    display: grid; 
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  .iconButton {
    cursor: pointer;
    font-weight: 400;
  }

  .error {
    color: red;
  }
`;
